<template>
  <!-- Modal para las opciones de impresión -->
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrarModal"
    @close="$emit('cerrar', false);"
  >
      <mdb-modal-header>
        <mdb-modal-title>Cargar al camión</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form id="form-cargar-camion" @submit.prevent>
          <header class="font-weight-bold">
            Lista de vehículos
          </header>
          <ul class="row contenedor-lista-ul mt-1 mb-2">
            <li
              v-for="vehiculo in listaDeVehiculos"
              :key="vehiculo.id_veh"
              class="col-12 col-sm-6 col-lg-4 py-2 opciones-card-radio"
            >
              <input
                :id="`vehiculo-${vehiculo.id_veh}`"
                v-model="vehiculoSeleccionado"
                type="radio"
                name="radio-vehiculos"
                :value="vehiculo"
              >
              <label :for="`vehiculo-${vehiculo.id_veh}`">
                <span class="icono" />
                <p class="texto">
                  <span class="font-weight-bold">
                    {{ vehiculo.mar_veh }} {{ vehiculo.mod_veh }}
                  </span>
                  ({{ vehiculo.col_veh }}) -
                  <span class="d-inline-block" title="Placa">
                    {{ vehiculo.pla_veh }}
                  </span>
                  <span class="d-block">
                    Carga:
                      <span title="Lleva">
                        {{ despachosO[vehiculo.id_veh] ? Number(despachosO[vehiculo.id_veh].pes_des) : 0 }}
                      </span> /
                      <span title="Máxima">{{ Number(vehiculo.cap_veh) }}</span>
                      kg
                  </span>
                  <small v-if="vehiculo.per_veh_nom">
                    Chofer: {{ `${vehiculo.per_veh_nom.nom_per} ${vehiculo.per_veh_nom.ape_per}` }}
                  </small>
                  <small class="d-block" v-if="despachosO[vehiculo.id_veh] && despachosO[vehiculo.id_veh].zonas">
                    Zonas: {{ mostrarZonasADespachar(despachosO[vehiculo.id_veh].zonas) }}
                  </small>
                  <small
                    class="d-block"
                    v-if="despachosO[vehiculo.id_veh]
                    && despachosO[vehiculo.id_veh].clientes
                    && Object.keys(despachosO[vehiculo.id_veh].clientes).length
                  ">
                    Clientes: {{ Object.keys(despachosO[vehiculo.id_veh].clientes).length }}
                  </small>
                </p>
              </label>
            </li>
          </ul>
          <header class="font-weight-bold">
            Lista de transportistas
          </header>
          <ul class="row contenedor-lista-ul mt-1 mb-2">
            <li
              v-for="chofer in transportistas"
              :key="chofer.id_per"
              class="col-12 col-sm-6 col-lg-4 py-2 opciones-card-radio"
            >
              <input
                :id="`transportistas-${chofer.id_per}`"
                v-model="transportistaSeleccionado"
                type="radio"
                name="radio-transportistas"
                :value="chofer"
              >
              <label :for="`transportistas-${chofer.id_per}`">
                <span class="icono" />
                <p class="texto">
                  <span class="font-weight-bold d-block">
                    {{ chofer.nom_per }} {{ chofer.ape_per }}
                  </span>
                  <small>{{ chofer.nom_cnt }}</small>
                </p>
              </label>
            </li>
          </ul>
        </form>
        <div class="row justify-content-around">
          <mdb-btn
            flat
            dark-waves
            icon="times"
            @click="$emit('cerrar', false);"
          >
            Cancelar
          </mdb-btn>
          <mdb-btn
            type="submit"
            form="form-cargar-camion"
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'truck'"
            :icon-class="[{'fa-spin': botonDeshabilitado}]"
            :disabled="botonDeshabilitado"
            @click="validarDatos"
          >
            Cargar
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import { mdbBtn, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody } from 'mdbvue'
export default {
  name: 'CargarCamion',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true
    },
    idDes: {
      type: Number,
      default: 0
    },
    transportistas: {
      type: Array,
      required: true
    },
    vehiculos: {
      type: Array,
      required: true
    },
    despachos: {
      type: Array,
      default: function () {
        return []
      }
    },
    zonas: {
      type: Object,
      default: function () {
        return {}
      }
    },
    carga: {
      type: Object,
      default: function () {
        return {}
      }
    },
    totalesPorPresentacion: {
      type: Object,
      required: true
    },
    presentaciones: {
      type: Object,
      required: false
    },
    transportistaDes: {
      type: String,
      default: ''
    },
    vehiculoDes: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      botonDeshabilitado: false,
      listaDeVehiculos: [],
      transportistaSeleccionado: {},
      vehiculoSeleccionado: {},
      validarValores: {},
      listaTransporte: {
        transportistas: [],
        vehiculos: []
      }
    }
  },
  computed: {
    despachosO () {
      const despachos = {}
      this.despachos.forEach(c => {
        if (!despachos[c.id_veh]) {
          despachos[c.id_veh] = {
            id_des: c.id_des,
            nom_per: c.nom_per,
            ape_per: c.ape_per,
            id_per: c.id_per_t_des,
            cap_veh: c.cap_veh,
            pes_des: c.pes_des,
            mar_veh: c.mar_veh,
            id_veh: c.id_veh,
            mod_veh: c.mod_veh,
            col_veh: c.col_veh,
            clientes: {},
            zonas: {}
          }
        }
        if (!despachos[c.id_veh].zonas[c.id_zon_suc]) {
          despachos[c.id_veh].zonas[c.id_zon_suc] = {
            id_zon: this.zonas[c.id_zon_suc].id_zon,
            est_zon: this.zonas[c.id_zon_suc].est_zon
          }
        }
        if (!despachos[c.id_veh].clientes[c.id_suc]) {
          despachos[c.id_veh].clientes[c.id_suc] = {
            id_suc: c.id_suc
          }
        }
      })
      return despachos
    }
  },
  methods: {
    datosTransVeh () {
      if (this.vehiculoDes) {
        this.listaDeVehiculos.forEach((v) => {
          if (v.id_veh === this.vehiculoDes) {
            this.vehiculoSeleccionado = v
          }
        })
      }
    },
    limpiarCampos () {
      this.botonDeshabilitado = false
      this.listaDeVehiculos = []
      this.transportistaSeleccionado = {}
      this.vehiculoSeleccionado = {}
      this.validarValores = {}
    },
    validarDatos () {
      this.validarValores = {
        transportista: Object.values(this.transportistaSeleccionado).length
          ? 'valido' : 'invalido',
        vehiculo: Object.values(this.vehiculoSeleccionado).length
          ? 'valido' : 'invalido'
      }
      if (!this.totalesPorPresentacion.cantidad) {
        return this.$emit('alerta-mensaje', {
          contenido: 'Selecciona productos a cargar',
          tipo: 'error'
        })
      }
      if (!this.vehiculoSeleccionado.id_veh) {
        return this.$emit('alerta-mensaje', {
          contenido: 'Selecciona el vehículo',
          tipo: 'error'
        })
      }
      if (!this.transportistaSeleccionado.id_per) {
        return this.$emit('alerta-mensaje', {
          contenido: 'Selecciona el transportista',
          tipo: 'error'
        })
      }
      /*
      ** Se debe eliminar la propiedad per_veh_nom porque no pertenece
      ** a la estructura normal del objeto de vehículos, solo se emplea
      ** para mostrar el nombre del chofer en el radio-button
      */
      if (this.vehiculoSeleccionado && this.vehiculoSeleccionado.per_veh_nom) {
        delete this.vehiculoSeleccionado.per_veh_nom
      }
      this.enviarCarga()
    },
    enviarCarga () {
      this.$emit('boton-deshabilitado', true)
      this.botonDeshabilitado = true
      let presentaciones
      const existePropPresentacion = !!Object.keys(this.presentaciones).length
      if (existePropPresentacion) {
        presentaciones = JSON.parse(JSON.stringify(this.presentaciones))
      }
      let data = ''

      if (this.idDes) {
        for (const pedido in this.carga) {
          if (Object.keys(this.carga[pedido]).length) {
            data += `id_ped[]=${pedido}&`
            for (const presentacion in this.carga[pedido]) {
              if (this.carga[pedido][presentacion]) {
                data += `pre-${presentacion}=${this.carga[pedido][presentacion]}&`
              }
            }
          }
        }

        for (const pre in this.totalesPorPresentacion) {
          if (Object.hasOwnProperty.call(this.totalesPorPresentacion, pre) && !isNaN(pre)) {
            data += `caj-${pre}=${this.totalesPorPresentacion[pre].cantidad}&pepr-${pre}=${this.totalesPorPresentacion[pre].peso}&vopr-${pre}=${this.totalesPorPresentacion[pre].volumen}&`
          }
        }
        data += `des=${this.idDes}&`
      } else {
        for (const zona in this.carga) {
          for (const pedido in this.carga[zona]) {
            if (Object.keys(this.carga[zona][pedido]).length) {
              data += `id_ped[]=${pedido}&`
              for (const presentacion in this.carga[zona][pedido]) {
                if (this.carga[zona][pedido][presentacion]) {
                  if (existePropPresentacion && Number(this.carga[zona][pedido][presentacion]) > Number(presentaciones[presentacion]?.can_pre)) {
                    this.$emit('boton-deshabilitado', false)
                    this.botonDeshabilitado = false
                    this.$emit('alerta-mensaje', {
                      contenido: `La cantidad ingresada es mayor a la cantidad disponible de la presentación ${this.presentaciones[presentacion].des_pre}`,
                      tipo: 'advertencia'
                    })
                    return
                  } else {
                    data += `pre-${presentacion}=${this.carga[zona][pedido][presentacion]}&`
                  }
                }
              }
            }
          }
        }

        for (const zon in this.totalesPorPresentacion) {
          for (const pre in this.totalesPorPresentacion[zon]) {
            if (Object.hasOwnProperty.call(this.totalesPorPresentacion[zon], pre) && !isNaN(pre)) {
              data += `caj-${pre}=${this.totalesPorPresentacion[zon][pre].cantidad}&pepr-${pre}=${this.totalesPorPresentacion[zon][pre].peso}&vopr-${pre}=${this.totalesPorPresentacion[zon][pre].volumen}&`
            }
          }
        }
      }
      data += `veh=${this.vehiculoSeleccionado.id_veh}&cho=${this.transportistaSeleccionado.id_per}&`
      data += `pes=${this.totalesPorPresentacion.peso}&vol=${this.totalesPorPresentacion.volumen}&`

      const url = this.idDes ? 'desActM' : 'guaDisM'
      apiPost({ s: url }, data)
        .then((res) => {
          this.$emit('boton-deshabilitado', false)
          this.botonDeshabilitado = false
          if (res.data.r) {
            // Envía mensaje al padre para emplear el componente AlertaMensaje
            this.$emit('alerta-mensaje', {
              contenido: res.data.e,
              tipo: 'correcto'
            })
            setTimeout(() => {
              this.$emit('cerrar', false)
              this.$emit('actualizar')
            }, 1500)
          } else {
            this.$emit('alerta-mensaje', {
              contenido: res.data.e,
              tipo: 'error'
            })
          }
        })
    },
    mostrarZonasADespachar (zona) {
      let zonas = ''
      let coma = ''
      for (const i in zona) {
        if (!zonas.includes(zona[i].est_zon)) {
          zonas += coma
          coma = ', '
          zonas += zona[i].est_zon
        }
      }
      return zonas
    }
  },
  watch: {
    mostrarModal: function () {
      if (this.mostrarModal) {
        // Ajustando datos de los vehículos para mostrar nombre del chofer
        this.vehiculos.forEach((vehiculo) => {
          const vehiculoEditado = {}
          Object.assign(vehiculoEditado, vehiculo)
          if (vehiculo.id_per_veh && vehiculo.id_per_veh.length) {
            this.transportistas.filter(chofer => {
              if (chofer.id_per === vehiculoEditado.id_per_veh) {
                vehiculoEditado.per_veh_nom = {
                  ape_per: chofer.ape_per,
                  id_per: chofer.id_per,
                  nom_per: chofer.nom_per
                }
              }
            })
          }
          this.listaDeVehiculos.push(vehiculoEditado)
          this.datosTransVeh()
        })
      } else this.limpiarCampos()
    },
    vehiculoSeleccionado: function () {
      if (this.vehiculoSeleccionado &&
      this.despachosO[this.vehiculoSeleccionado.id_veh] &&
      this.despachosO[this.vehiculoSeleccionado.id_veh].id_per) {
        this.transportistas.filter(chofer => {
          if (this.despachosO[this.vehiculoSeleccionado.id_veh].id_per === chofer.id_per) {
            this.transportistaSeleccionado = chofer
          }
        })
      } else if (this.vehiculoSeleccionado &&
        this.vehiculoSeleccionado.id_per_veh &&
        this.vehiculoSeleccionado.id_per_veh.length) {
        this.transportistas.filter(chofer => {
          if (this.vehiculoSeleccionado.id_per_veh === chofer.id_per) {
            this.transportistaSeleccionado = chofer
          }
        })
      } else this.transportistaSeleccionado = {}
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
