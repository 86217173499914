<template>
<section>
  <div v-if="!cargando" class="d-flex flex-column align-items-end">
    <template>
      <ul class="table">
        <li class="encabezado">
          <div class="thead claro">
            <div class="col-4 col-sm-3 d-none d-sm-block">
              Tipo de precio
              <small class="d-block text-muted">Agregar descuento</small>
            </div>
            <div class="col-5 col-sm col-xl-auto col-custom">
              Descuento
            </div>
            <div class="col-5">
              Documento
            </div>
            <div class="col-sm d-none d-sm-block" data-column="acciones">
              Acciones
            </div>
          </div>
        </li>
        <template v-for="(restriccion, index_res) in iterarDatos">
          <li class="contenido-subtitulo claro clearfix d-flex flex-column justify-content-center align-items-center d-sm-block" :key="index_res">
            <p class="py-2 px-2 d-inline-block">{{ restriccion.cp }}</p>
            <mdb-btn
              flat
              dark-waves
              icon="plus"
              type="button"
              :disabled="deshabilitarBoton"
              v-if="restriccion.id_cp_rped"
              @click="agregarFila('precio', restriccion.id_rped), mostrarModal = true"
              class="my-0 py-1 px-2 float-right"
            >
              Agregar tipo de precio
            </mdb-btn>
          </li>
          <li
            v-for="(tpre, index_tpre) in restriccion.tp"
            :key="`tpre-${index_tpre}*${index_res}`"
            class="contenido"
          >
            <div class="col-12 col-sm-3 p-0 p-sm-3 d-flex flex-sm-column justify-content-center align-items-center align-items-md-start">
              <span class="p-2">{{ tpre.nom }}</span>
              <mdb-btn
                flat
                dark-waves
                icon="plus"
                type="button"
                :disabled="deshabilitarBoton"
                v-if="tpre.id_tpre_rped && restriccion.id_rped"
                @click="agregarFila('descuento', restriccion.id_rped, tpre.id_tpre_rped), mostrarModal = true"
                class="p-2 ml-md-0"
              >
                <span class="d-sm-none">Agregar </span><span class="d-sm-none d-md-inline-block">Descuento</span>
              </mdb-btn>
            </div>
            <div class="col p-0">
              <div
                v-for="(det, index_det) in tpre.det"
                :key="`det-${index_det}`"
                class="row mx-0 py-1 filas-internas align-items-center"
              >
                <p class="col-5 col-md-auto col-custom">
                  {{det.descuento}}
                </p>
                <div class="col p-0">
                  <div v-for="(fac, index_fac) in det.factura" :key="index_fac" class="d-flex justify-content-between align-items-center">
                    <p class="col-auto p-0">
                      {{fac.documento}}
                    </p>
                    <div class="col-auto py-0 pl-0" data-column="acciones">
                      <mdb-dropdown class="d-md-none">
                        <mdb-dropdown-toggle slot="toggle" class="p-1 z-depth-0">
                          <mdb-btn
                            flat
                            dark-waves
                            icon="cog"
                            class="p-1"
                            :title="`Agregar descuento en ${tpre.factura}`"
                          />
                        </mdb-dropdown-toggle>
                        <mdb-dropdown-menu>
                          <mdb-dropdown-item>
                            <h6 class="dropdown-header">Acciones en {{tpre.nom}}</h6>
                          </mdb-dropdown-item>
                          <mdb-dropdown-item>
                            <mdb-btn
                              :disabled="deshabilitarBoton"
                              flat
                              dark-waves
                              :icon="fac.hab_rped === '1' ? 'ban' : 'check-circle'"
                              type="button"
                              title="Modificar estatus"
                              @click="restriccionActualizarEstatus= {hab_rped: fac.hab_rped, id_rped: fac.id_rped}; modalConfirmacionEliminar=true;"
                              class="m-0 p-2"
                            >
                              {{fac.hab_rped === '1' ? 'Deshabilitar' : 'Habilitar'}}
                            </mdb-btn>
                          </mdb-dropdown-item>
                          <mdb-dropdown-item>
                            <mdb-btn
                              v-if="restriccionesClientes && restriccionesClientes.length && fac.id_rped"
                              :disabled="deshabilitarBoton"
                              flat
                              dark-waves
                              icon="trash"
                              type="button"
                              title="Eliminar"
                              @click="eliminarRestriccion(fac.id_rped)"
                              class="m-0 p-2"
                            >
                              Eliminar
                            </mdb-btn>
                          </mdb-dropdown-item>
                        </mdb-dropdown-menu>
                      </mdb-dropdown>
                      <mdb-btn
                        :disabled="deshabilitarBoton"
                        flat
                        dark-waves
                        :icon="fac.hab_rped === '1' ? 'ban' : 'check-circle'"
                        type="button"
                        title="Modificar estatus"
                        @click="restriccionActualizarEstatus= {hab_rped: fac.hab_rped, id_rped: fac.id_rped}; modalConfirmacionEliminar=true;"
                        class="m-0 p-2 d-none d-md-inline-block"
                      >
                        {{fac.hab_rped === '1' ? 'Deshabilitar' : 'Habilitar'}}
                      </mdb-btn>
                      <mdb-btn
                        v-if="restriccionesClientes && restriccionesClientes.length && fac.id_rped"
                        :disabled="deshabilitarBoton"
                        flat
                        dark-waves
                        icon="trash"
                        type="button"
                        title="Eliminar"
                        @click="eliminarRestriccion(fac.id_rped)"
                        class="m-0 p-2 d-none d-md-inline-block"
                      >
                        Eliminar
                      </mdb-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </template>
        <template>
          <li v-if="!Object.keys(iterarDatos).length" class="mensaje-no-items pb-3">
            <font-awesome-icon
              icon="ban"
              size="5x"
              class="icono"
            />
            <p class="texto">No hay restricciones registradas</p>
          </li>
        </template>
      </ul>
      <mdb-btn
        color="primario"
        class="sticky-bottom"
        icon="plus"
        @click="abrirModal()"
      >
        Agregar Restricción
      </mdb-btn>
    </template>
  </div>
  <mdb-modal
    centered
    elegant
    scrollable
    size="md"
    :show="mostrarModal"
    @close="eliminarRestriccionFila(), cerrarModal()"
  >
    <mdb-modal-header class="pb-0">
      <mdb-modal-title>
        Agregar Restricción
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div v-if="!cp">
            <p>
              {{obtenerNombreCondicionPagoPorId(restriccionDatos.id_cp_rped, 'condicion_pago')}}
              <small class="small-block">Condición de pago</small>
            </p>
          </div>
          <div v-else class="md-form md-outline outline-select my-2">
            <select
              id="condicion-pago"
              v-model="restriccionDatos.id_cp_rped"
              class="custom-select"
            >
              <option
                v-for="condiciones in condicionesPago"
                :key="`condicion-pago-${condiciones.id_cp}`"
                :value="condiciones.id_cp"
              >
                {{ condiciones.nom_cp }}
              </option>
              <option value="" class="d-none" disabled>Seleccione</option>
            </select>
            <label for="condicion-pago" :class="restriccionDatos.id_cp_rped.length ? 'label-active' : 'label-inactive'">Condición de pago</label>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div v-if="!cp">
            <p>
              {{obtenerNombreCondicionPagoPorId(restriccionDatos.id_tdoc_rped, 'tipo_documento')}}
              <small class="small-block">Tipo de documento</small>
            </p>
          </div>
          <div v-else class="md-form md-outline outline-select my-2">
            <select
              id="tipo-documento"
              v-model="restriccionDatos.id_tdoc_rped"
              class="custom-select"
            >
              <option
                v-for="documento in tiposDocumento"
                :value="documento.id_tdoc"
                :key="documento.id_tdoc"
              >
                {{ documento.nom_tdoc }}
              </option>
              <option value="" class="d-none" disabled>Seleccione</option>
            </select>
            <label for="tipo-documento" :class="restriccionDatos.id_tdoc_rped.length ? 'label-active' : 'label-inactive'">Tipo de documento</label>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div v-if="!tp">
            <p>
              {{obtenerNombreCondicionPagoPorId(restriccionDatos.id_tpre_rped, 'tipo_precio')}}
              <small class="small-block">Tipo de precio</small>
            </p>
          </div>
          <div v-else class="md-form md-outline outline-select my-2">
            <select
              id="tipo-precio"
              v-model="restriccionDatos.id_tpre_rped"
              class="custom-select"
            >
              <option
                v-for="precios in tiposPrecio"
                :key="`precios-${precios.id_tpre}`"
                :value="precios.id_tpre"
              >
                {{ precios.nom_tpre }}
              </option>
              <option value="" class="d-none" disabled>Seleccione</option>
            </select>
            <label for="tipo-precio" :class="restriccionDatos.id_tpre_rped.length ? 'label-active' : 'label-inactive'">Tipo de precio</label>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="md-form md-outline outline-select my-2">
            <select
              id="promocion"
              v-model="restriccionDatos.id_prm_rped"
              class="custom-select"
            >
              <option
                v-for="promocion in promocionesPago"
                :value="promocion.id_prm"
                :key="promocion.id_prm"
              >
                {{ promocion.nom_prm }}
              </option>
              <option value="" class="d-none" disabled>Seleccione</option>
            </select>
            <label for="promocion" :class="restriccionDatos.id_prm_rped.length ? 'label-active' : 'label-inactive'">Promoción</label>
          </div>
        </div>
      </div>
      <div class="mt-3 row justify-content-around">
        <mdb-btn
          :disabled="deshabilitarBoton"
          flat
          dark-waves
          icon="times"
          type="button"
          title="Cancelar"
          @click="eliminarRestriccionFila(), cerrarModal()"
        >
          Cancelar
        </mdb-btn>
        <mdb-btn
          :disabled="deshabilitarBoton"
          color="primario"
          icon="plus"
          type="button"
          title="Agregar restricción"
          @click="agregarRestriccion()"
        >
          Agregar restricción
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
  <div v-if="cargando" class="mensaje-no-items py-3">
    <LoaderKel />
    <p class="texto mt-2">Cargando</p>
  </div>
  <AlertaMensaje
    :alertaMensaje="alertaMensaje"
    @cerrar="alertaMensaje.contenido = ''"
  />
  <ConfirmacionEliminar
    :boton-deshabilitado="deshabilitarBoton"
    :mostrar-modal="modalConfirmacionEliminar"
    :titulo="`${restriccionActualizarEstatus && restriccionActualizarEstatus.hab_rped === '1' ? 'Se deshabilitará la restricción' : 'Se habilitará la restricción'}`"
    :mensaje="`${restriccionActualizarEstatus && restriccionActualizarEstatus.hab_rped === '1'
                ? 'Esta acción deshabilitará esta restricción y no estará disponible en la toma de pedido de los vendedores. ¿Desea continuar?'
                : 'Esta acción habilitará esta combinación de restricción en la toma de pedido de los vendedores. ¿Desea continuar?'
              }`"
    :textoBotonConfirmar="restriccionActualizarEstatus && restriccionActualizarEstatus.hab_rped && restriccionActualizarEstatus.hab_rped === '1' ? 'Sí, Deshabilitar' : 'Sí, Habilitar'"
    @cerrar="modalConfirmacionEliminar = false; restriccionActualizarEstatus=null"
    @confirmar="toggleEstatusRestriccion(restriccionActualizarEstatus); modalConfirmacionEliminar=false;"
  />
</section>
</template>

<script>
import LoaderKel from '@/components/LoaderKel.vue'
import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
export default {
  name: 'TablaRestriccion',
  components: {
    LoaderKel,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    AlertaMensaje,
    ConfirmacionEliminar
  },
  props: {
    restriccionesClientes: {
      type: Array,
      required: true
    },
    clienteId: {
      type: String,
      required: true
    },
    nombreCliente: {
      type: String,
      required: true
    },
    condicionesPago: {
      type: Array,
      required: true
    },
    tiposPrecio: {
      type: Array,
      required: true
    },
    tiposDocumento: {
      type: Array,
      required: true
    },
    promocionesPago: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      alertaMensaje: {
        contenido: '',
        tipo: ''
      },
      deshabilitarBoton: false,
      modalConfirmacionEliminar: false,
      restriccionActualizarEstatus: null,
      cargando: false,
      esAgregarFila: false,
      restriccionDatos: {
        id_tcl_rped: this.clienteId,
        id_cp_rped: '',
        id_tpre_rped: '',
        id_tdoc_rped: '1',
        id_prm_rped: ''
      },
      iterarDatos: {},
      mostrarModal: false,
      tp: false,
      cp: false
    }
  },
  computed: {
    conPago () {
      if (!this.condicionesPago) return {}
      const conPago = {}
      this.condicionesPago.forEach((cp) => {
        if (!conPago[cp.id_cp]) {
          conPago[cp.id_cp] = {
            nom_cp: cp.nom_cp,
            id_cp: cp.id_cp
          }
        }
      })
      return conPago
    },
    proPago () {
      if (!this.promocionesPago) return {}
      const proPago = {}
      this.promocionesPago.forEach((prm) => {
        if (!proPago[prm.id_prm]) {
          proPago[prm.id_prm] = {
            nom_prm: prm.nom_prm,
            id_prm: prm.id_prm
          }
        }
      })
      return proPago
    },
    tipDocumento () {
      if (!this.tiposDocumento) return {}
      const tipDocumento = {}
      this.tiposDocumento.forEach((tdoc) => {
        if (!tipDocumento[tdoc.id_tdoc]) {
          tipDocumento[tdoc.id_tdoc] = {
            nom_tdoc: tdoc.nom_tdoc,
            id_tdoc: tdoc.id_tdoc
          }
        }
      })
      return tipDocumento
    },
    tipPrecio () {
      if (!this.tiposPrecio) return {}
      const tipPrecio = {}
      this.tiposPrecio.forEach((tpre) => {
        if (!tipPrecio[tpre.id_tpre]) {
          tipPrecio[tpre.id_tpre] = {
            nom_tpre: tpre.nom_tpre,
            id_tpre: tpre.id_tpre
          }
        }
      })
      return tipPrecio
    }
  },
  methods: {
    abrirModal () {
      this.mostrarModal = true
      this.tp = true
      this.cp = true
      this.resetearCampos(true)
    },
    cerrarModal () {
      this.mostrarModal = false
      this.tp = false
      this.cp = false
    },
    resetearCampos (todo) {
      this.esAgregarFila = false
      this.restriccionDatos = {
        id_tcl_rped: this.clienteId,
        id_cp_rped: '',
        id_tpre_rped: '',
        id_tdoc_rped: todo ? '' : '1',
        id_prm_rped: ''
      }
    },
    iterarDatosRes () {
      const resCli = {}
      this.restriccionesClientes.forEach(res => {
        if (this.conPago[res.id_cp_rped]) {
          if (!resCli[res.id_cp_rped]) {
            resCli[res.id_cp_rped] = {
              id_rped: res.id_rped,
              id_cp_rped: res.id_cp_rped,
              cp: this.conPago[res.id_cp_rped].nom_cp,
              tp: {}
            }
          }
          if (this.tipPrecio[res.id_tpre_rped]) {
            if (!resCli[res.id_cp_rped].tp[res.id_tpre_rped]) {
              resCli[res.id_cp_rped].tp[res.id_tpre_rped] = {
                nom: this.tipPrecio[res.id_tpre_rped].nom_tpre,
                id_tpre_rped: res.id_tpre_rped,
                det: {}
              }
            }
          }
          if (this.proPago[res.id_prm_rped]) {
            if (!resCli[res.id_cp_rped].tp[res.id_tpre_rped].det[res.id_prm_rped]) {
              resCli[res.id_cp_rped].tp[res.id_tpre_rped].det[res.id_prm_rped] = {
                id_prm_rped: res.id_prm_rped,
                descuento: this.proPago[res.id_prm_rped].nom_prm,
                id_tdoc_rped: res.id_tdoc_rped,
                factura: {}
              }
            }
          }
          if (this.tipDocumento[res.id_tdoc_rped]) {
            if (!resCli[res.id_cp_rped].tp[res.id_tpre_rped].det[res.id_prm_rped].factura[res.id_tdoc_rped]) {
              resCli[res.id_cp_rped].tp[res.id_tpre_rped].det[res.id_prm_rped].factura[res.id_tdoc_rped] = {
                id_rped: res.id_rped,
                hab_rped: res.hab_rped,
                id_tdoc_rped: res.id_tdoc_rped,
                documento: this.tipDocumento[res.id_tdoc_rped].nom_tdoc
              }
            }
          }
        }
      })
      this.iterarDatos = resCli
    },
    agregarRestriccion () {
      this.deshabilitarBoton = true
      if (!this.restriccionDatos.id_cp_rped || !this.restriccionDatos.id_tpre_rped || !this.restriccionDatos.id_tdoc_rped || this.restriccionDatos.id_prm_rped === '') {
        this.alertaMensaje = {
          contenido: 'Debe seleccionar todos los campos',
          tipo: 'advertencia'
        }
        this.deshabilitarBoton = false
        return
      }
      const restricciones = this.restriccionesClientes.filter((restriccion) => restriccion.id_tcl_rped === this.restriccionDatos.id_tcl_rped && restriccion.id_cp_rped === this.restriccionDatos.id_cp_rped && restriccion.id_tpre_rped === this.restriccionDatos.id_tpre_rped && restriccion.id_tdoc_rped === this.restriccionDatos.id_tdoc_rped && restriccion.id_prm_rped === String(this.restriccionDatos.id_prm_rped))
      if (restricciones.length) {
        this.alertaMensaje = {
          contenido: `Ya existe una restricción con los mismos datos para el tipo de cliente ${this.nombreCliente}, verifica las condiciones de pago`,
          tipo: 'advertencia'
        }
        this.deshabilitarBoton = false
        return
      }
      this.$emit('agregar-restriccion', {
        id_tcl_rped: this.clienteId,
        id_cp_rped: this.restriccionDatos.id_cp_rped,
        id_tpre_rped: this.restriccionDatos.id_tpre_rped,
        id_tdoc_rped: this.restriccionDatos.id_tdoc_rped,
        id_prm_rped: this.restriccionDatos.id_prm_rped
      })
      this.resetearCampos()
      this.cerrarModal()
      this.deshabilitarBoton = false
    },
    eliminarRestriccion (idRest) {
      this.deshabilitarBoton = true
      this.$emit('eliminar-restriccion', {
        id_rped: idRest
      })
      this.deshabilitarBoton = false
    },
    obtenerNombreCondicionPagoPorId (id, tipoCondicion) {
      if (tipoCondicion === 'condicion_pago' && id) {
        return this.condicionesPago.length && this.condicionesPago.filter((condicion) => condicion.id_cp === id)[0].nom_cp
      }
      if (tipoCondicion === 'tipo_precio' && id) {
        return this.tiposPrecio.length && this.tiposPrecio.filter((precio) => precio.id_tpre === id)[0].nom_tpre
      }
      if (tipoCondicion === 'tipo_documento' && id) {
        return this.tiposDocumento.length && this.tiposDocumento.filter((documento) => documento.id_tdoc === id)[0].nom_tdoc
      }
      if (tipoCondicion === 'descuento' && id) {
        return this.promocionesPago.length && this.promocionesPago.filter((promocion) => promocion.id_prm === id)[0].nom_prm
      }
      return 'No especificado'
    },
    agregarFila (tipoCondicion, restriccionId, tpId) {
      this.esAgregarFila = true
      // En caso ya exista una fila sin id, mostrar alerta
      const existeFilaAnterior = this.restriccionesClientes.some((restriccion) => !restriccion.id_rped)
      if (existeFilaAnterior) {
        this.alertaMensaje = {
          contenido: `Debe agregar/eliminar la restricción anterior del tipo de cliente ${this.nombreCliente} para añadir una nueva`,
          tipo: 'advertencia'
        }
        return
      }
      const restriccionDatos = this.restriccionesClientes.filter((restriccion) => restriccion.id_rped === restriccionId)[0]
      if (tipoCondicion === 'precio') {
        this.tp = true
        this.restriccionDatos = {
          id_tcl_rped: this.clienteId,
          id_cp_rped: restriccionDatos.id_cp_rped,
          id_tdoc_rped: restriccionDatos.id_tdoc_rped,
          id_prm_rped: '',
          id_tpre_rped: ''
        }
        this.restriccionesClientes.push({
          id_cp_rped: restriccionDatos.id_cp_rped,
          id_tdoc_rped: restriccionDatos.id_tdoc_rped,
          id_tpre_rped: '',
          id_prm_rped: ''
        })
        return
      }
      if (tipoCondicion === 'descuento') {
        this.restriccionDatos = {
          id_tcl_rped: this.clienteId,
          id_cp_rped: restriccionDatos.id_cp_rped,
          id_tdoc_rped: restriccionDatos.id_tdoc_rped,
          id_tpre_rped: tpId,
          id_prm_rped: ''
        }
        this.restriccionesClientes.push({
          id_cp_rped: restriccionDatos.id_cp_rped,
          id_tpre_rped: tpId,
          id_tdoc_rped: restriccionDatos.id_tdoc_rped,
          id_prm_rped: ''
        })
      }
    },
    eliminarRestriccionFila () {
      this.restriccionesClientes.splice(this.restriccionesClientes.length - 1, 1)
      this.resetearCampos()
    },
    toggleEstatusRestriccion (restriccionDatos) {
      this.deshabilitarBoton = true
      if (restriccionDatos.hab_rped === '1') {
        this.$emit('actualizar-estatus', {
          id_rped: restriccionDatos.id_rped,
          hab_rped: '0'
        })
        this.deshabilitarBoton = false
        this.restriccionActualizarEstatus = null
        return
      }
      this.$emit('actualizar-estatus', {
        id_rped: restriccionDatos.id_rped,
        hab_rped: '1'
      })
      this.deshabilitarBoton = false
      this.restriccionActualizarEstatus = null
    }
  },
  mounted () {
    this.iterarDatosRes()
  },
  watch: {
    restriccionesClientes: function () {
      this.iterarDatosRes()
    }
  }
}
</script>

<style lang="scss" scoped>
ul.table {
  [class|="col"] {
    padding: .8rem .25rem;
    @media (min-width: 576px) {
      padding: .8rem 1rem;
    }
  }
  [data-column="acciones"] {
    text-align: right;
  }
}

.dropdown {
  .btn {
    box-shadow: none;

    &:focus, &:hover {
      box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    position: absolute !important;
    left: auto;
    right: -2.5rem;
  }
}

.col-custom {
  @media screen and (min-width: 768px) {
    flex: none;
    max-width: 100%;
    width: 150px;
  }
  @media screen and (min-width: 992px) {
    width: 180px;
  }
  @media screen and (min-width: 1200px) {
    width: 250px;
  }
}

.sticky-bottom {
  position: sticky;
  bottom: 10px;
}
</style>
