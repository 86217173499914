<template>
  <section>
    <transition
      mode="out-in"
      name="tabActiva-transicion"
    >
      <div
        v-if="despachoSeleccionado && Object.entries(despachoSeleccionado).length"
        key="DetallesDelDespacho"
        class="mx-5"
      >
        <mdb-btn
          dark-waves
          flat
          icon="arrow-left"
          title="Regresar a Despachos"
          class="no-print"
          @click="despachoSeleccionado = {}; datosPedidos = {}; cargaPedido = {}"
        >
          Despachos
        </mdb-btn>
        <div class="mt-2 mb-4">
          <TablaDePedidos
            class="maxH-80"
            mostrar-encabezado
            permitir-eliminar
            mostrar-filtros-categoria
            :categorias="categorias"
            :datos-pedidos="datosPedidos"
            :identificador="despachoSeleccionado.id_des"
            :titulo="`Clientes despachados por: ${despachoSeleccionado.nom_per} ${despachoSeleccionado.ape_per}`"
            :totales-por-presentacion="totalesPorPresentacion"
            :totales-por-presentacion-pedidos="totalesPorPresentacionPedidos"
            :cargaPedido="cargaPedido"
            :nuevos="nuevos"
            :vigilantes="vigilantes"
            :montacarguistas="montacarguistas"
            :almacenistas="almacenistas"
            :maximo="maximo"
            :deshabilitarBotonCargar="deshabilitarBotonCargar"
            @todos="seleccionarTodos($event.pedido, $event.checked)"
            @actualizar="calcularTotales"
            @cargar="cargarAlCamion($event)"
            @eliminar="eliminarPedido($event)"
            @eliminarPresentacion="eliminarPresentacion($event)"
          />
        </div>
      </div>
      <div
        v-else
        key="ListaDeDespachos"
        class="container"
      >
        <div class="pt-4">
          <header class="h4-responsive">
            Despachos cargando
          </header>
          <div
            v-if="!Object.keys(despachos).length"
            class="mensaje-no-items pb-3"
          >
            <font-awesome-icon
              icon="calendar-times"
              size="5x"
              class="icono"
            />
            <p class="texto">No hay despachos cargando</p>
          </div>
          <ul v-else class="table despachos">
            <li class="encabezado sticky">
              <div class="thead claro">
                <div class="col-12 col-md-3">
                  Transportista
                  <span class="small-block">
                    Sucursales - Zonas
                  </span>
                </div>
                <div class="col-12 col-md-3 col-lg-2 text-right">
                  Carga Actual
                  <span class="small-block">
                    Carga Máxima
                  </span>
                </div>
                <div class="col-12 col-md-6 col-lg d-flex justify-content-center">Acciones</div>
              </div>
            </li>
            <!-- Mensaje si no hay despachos de hoy -->
            <li
              class="contenido"
              v-for="d in despachos"
              :key="d.id_des"
              @click="despachoSeleccionado = d; verDetallesDespacho(despachoSeleccionado.id_des)"
            >
              <div
                class="col-12 col-md-3"
                data-columna="Transportista"
              >
                <div>
                  <span
                    title="Número de despacho"
                    class="text-muted"
                  >
                    {{d.id_des}} -
                  </span>
                  {{d.nom_per}} {{d.ape_per}}
                  <span class="d-none d-md-block small-block">
                    <span
                      title="Cantidad de sucursales a despachar"
                      class="text-muted"
                    >
                      {{ d.sucursales.length }} -
                    </span>
                    {{ mostrarZonasDespacho(d.zonas, d.id_des) }}
                  </span>
                </div>
              </div>
              <div
                class="col-12 d-md-none"
                data-columna="Sucursales - Zonas"
              >
                <div>
                  <span
                    title="Cantidad de sucursales a despachar"
                    class="text-muted"
                  >
                  {{ d.sucursales.length }} -
                  </span>
                  {{ mostrarZonasDespacho(d.zonas) }}
                </div>
              </div>
              <div
                class="col-12 col-md-3 col-lg-2 text-md-right"
                data-columna="Carga Actual"
              >
                <div>
                  {{convertirValorAFixed(d.pes_des)}}
                  <span class="d-none d-md-block small-block">
                    {{convertirValorAFixed(d.cap_veh)}}
                  </span>
                </div>
              </div>
              <div
                class="col-12 d-md-none"
                data-columna="Carga Máxima"
              >
                <div>
                  {{convertirValorAFixed(d.cap_veh)}}
                </div>
              </div>
              <div
                class="col-12 col-md-6 col-lg"
                data-columna="Acciones"
                @click.stop
              >
                <div class="row wrap justify-content-around">
                  <div class="col-auto order-lg-1 px-2">
                    <mdb-btn
                      color="secundario"
                      icon="plus"
                      @click="aggDatos = d.id_des; detallesDespacho = true"
                      class=" mx-0 my-1 px-2"
                    >
                      <!-- @click="verDetallesDespacho(d.id_des); aggDatos = true; aggDatosPersonal = d" -->
                      Agregar datos
                    </mdb-btn>
                  </div>
                  <div class="col-auto order-lg-2 px-2">
                    <mdb-btn
                      color="primario"
                      icon="box-open"
                      class=" mx-0 my-1 px-2"
                      @click="verDetallesDespacho(d.id_des); modalConfirmacionEliminar = true; despachar = d.id_des"
                    >
                      Despachar
                    </mdb-btn>
                  </div>
                  <div class="col-auto px-2">
                    <mdb-dropdown class=" mx-0 my-1 px-2 text-center text-md-left btn-dropdown-100">
                      <mdb-dropdown-toggle slot="toggle" color="flat" class="px-3 py-2 my-1">
                        <font-awesome-icon icon="print" />
                        Imprimir
                      </mdb-dropdown-toggle>
                      <mdb-dropdown-menu>
                        <mdb-dropdown-item :class="{'disabled': estaCargando}" @click="imprimir('OrdenDeSalida', d.id_des)">
                          <font-awesome-icon icon="file-pdf" />
                          Orden de salida
                        </mdb-dropdown-item>
                        <mdb-dropdown-item @click="imprimir('NotaDeEntrega', d.id_des)">
                          <font-awesome-icon icon="file-pdf" />
                          Nota de entrega
                        </mdb-dropdown-item>
                      </mdb-dropdown-menu>
                    </mdb-dropdown>
                  </div>
                  <div class="col-auto px-2">
                    <mdb-btn
                      dark-waves
                      flat
                      icon="trash"
                      class=" mx-0 my-1 px-2"
                      @click="modalConfirmacionEliminar = true; despachoEli = d.id_des"
                    >
                      Eliminar
                    </mdb-btn>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="pt-4">
          <header class="h4-responsive mx-0">Despachos de hoy</header>
          <!-- Mensaje si no hay despachos de hoy -->
          <div
            v-if="!Object.keys(despachosHoy).length"
            class="mensaje-no-items pb-3"
          >
            <font-awesome-icon
              icon="calendar-times"
              size="5x"
              class="icono"
            />
            <p class="texto">No hay despachos del día de hoy</p>
          </div>
          <ul
            v-for="dh in despachosHoy"
            :key="dh.id_des"
            class="table despachos"
          >
            <li class="encabezado sticky">
              <div class="row align-items-center justify-content-center mx-0">
                <div class="col-12 col-md px-0">
                  <header class="h4-responsive">
                    <span
                      title="Número de despacho"
                      class="text-muted"
                    >
                      {{dh.id_des}} -
                    </span>
                    Transportista: {{dh.nom_per}} {{dh.ape_per}}
                  </header>
                </div>
                <mdb-dropdown class="col-10 col-sm-5 col-md-auto px-0 text-center text-md-left btn-dropdown-100">
                  <mdb-dropdown-toggle
                    slot="toggle"
                    color="flat"
                    class="px-3 py-2"
                  >
                    <font-awesome-icon icon="print" />
                    Imprimir
                  </mdb-dropdown-toggle>
                  <mdb-dropdown-menu>
                    <mdb-dropdown-item :class="{'disabled': estaCargando}" @click="imprimir('OrdenDeSalida', dh.id_des)">
                      <font-awesome-icon icon="file-pdf" />
                        Orden de salida
                    </mdb-dropdown-item>
                    <mdb-dropdown-item @click="imprimir('NotaDeEntrega',dh.id_des)">
                      <font-awesome-icon icon="file-pdf" />
                        Nota de entrega
                      </mdb-dropdown-item>
                  </mdb-dropdown-menu>
                </mdb-dropdown>
                <mdb-btn
                  color="primario"
                  icon="clipboard-check"
                  class="col-10 col-sm-5 col-md-auto px-3 py-2 mr-md-3"
                  title="Pasar a estatus Completo los pedidos de este despacho que se encuentren en estatus Incompleto"
                  @click="despachoCom = dh.id_des; modalConfirmacionEliminar = true"
                >
                  Pasar a completo
                </mdb-btn>
              </div>
              <div class="thead claro">
                <div class="col">Cliente</div>
                <div class="col-sm-4">Zona</div>
              </div>
            </li>
            <li
              class="contenido"
              v-for="dhc in dh.clientes"
              :key="dhc.nom_emp+dh.id_des"
            >
              <div class="col" data-columna="Cliente">
                {{dhc.nom_emp}} - {{ dhc.nom_suc }}
              </div>
              <div
                class="col col-sm-4"
                data-columna="Zona"
              >
                {{dhc.est_zon}}
              </div>
            </li>
          </ul>
        </div>
        <div class="pt-4">
          <div class="row justify-content-center align-items-center mb-2">
            <header class="h4-responsive mx-0 col">Resumen de cantidades despachadas del día</header>
            <div v-if="Object.keys(despachosHoyCantidad).length && Object.keys(despachosHoyCantidad.productos).length" class="col-8 col-sm-auto mr-sm-3 px-0">
              <mdb-btn
                block
                flat
                dark-waves
                icon="print"
                class="px-sm-3"
                @click="imprimirResumenCantidades()"
              >
                Imprimir
              </mdb-btn>
            </div>
          </div>
          <div
            v-if="Object.keys(despachosHoyCantidad).length && Object.keys(despachosHoyCantidad.productos).length"
            class="row mb-2"
          >
            <p class="col-12 col-md-6 order-md-1 mb-1">
              <span class="font-weight-bold">Vehículos:</span>
              {{despachosHoyCantidad.vehiculos}}
            </p>
            <p class="col-12 col-md-6 order-md-3 mb-1">
              <span class="font-weight-bold">Conductores:</span>
              {{despachosHoyCantidad.transportistas}}
            </p>
            <p class="col-12 col-md-6 order-md-2 mb-1">
              <span class="font-weight-bold">Vendedores:</span>
              {{despachosHoyCantidad.vendedores}}
            </p>
            <p class="col-12 col-md-6 order-md-4 mb-1">
              <span class="font-weight-bold">Estados:</span>
              {{despachosHoyCantidad.estados}}
            </p>
            <p class="col-12 col-md-6 order-md-4 mb-1">
              <span class="font-weight-bold">Clientes:</span>
              {{despachosHoyCantidad.clientes}}
            </p>
          </div>
          <ul v-if="Object.keys(despachosHoyCantidad).length && Object.keys(despachosHoyCantidad.productos).length" class="table despachos">
            <li class="encabezado sticky">
              <header class="h5-responsive">Cantidades despachadas</header>
              <div class="thead claro">
                <div class="col-12 col-md-2 col-xl-1">Código</div>
                <div class="col-12 col-md-3">Descripción</div>
                <div class="col-12 col-md text-center">Estados</div>
                <div class="col-12 col-md-2 col-xl-1 text-right">Clientes</div>
                <div class="col-12 col-md-2 text-right">
                  Total Peso
                  <span class="small-block">
                    Cantidad
                  </span>
                </div>
              </div>
            </li>
            <li v-for="(despro, index) in despachosHoyCantidad.productos" :key="index" class="contenido">
              <div class="col-12 col-md-2 col-xl-1 py-1" data-columna="Código">{{despro.codigo}}</div>
              <div class="col-12 col-md-3 py-1" data-columna="Descripción">{{despro.producto+' '+despro.presentacion}}</div>
              <div class="col-12 col-md text-md-center py-1" data-columna="Estados">{{despro.estados}}</div>
              <div class="col-12 col-md-2 col-xl-1 py-1 text-md-right" data-columna="Clientes">{{despro.clientes}}</div>
              <div class="col-12 d-md-none" data-columna="Cantidad">{{despro.cantidad}}</div>
              <div class="col-12 col-md-2 text-md-right py-1" data-columna="Peso">
                {{despro.pesoTotal}} kg
                <span class="d-none d-md-block small-block">
                  {{despro.cantidad}}
                </span>
              </div>
            </li>
            <li class="contenido claro font-weight-bold">
              <div class="col d-none d-md-block text-uppercase py-1">Totales</div>
              <div class="col-12 col-md-2 col-xl-1 text-md-right py-1" data-columna="Totales cantidad">{{despachosHoyCantidad.totales.cantidad}}</div>
              <div class="col-12 col-md-2 text-md-right py-1" data-columna="Totales peso">{{Number(despachosHoyCantidad.totales.peso.toFixed(2))}} kg</div>
            </li>
          </ul>
          <div v-else class="mensaje-no-items pb-3">
            <font-awesome-icon
              icon="calendar-times"
              size="5x"
              class="icono"
            />
            <p class="texto">No hay cantidades despachadas del día de hoy</p>
          </div>
        </div>
      </div>
    </transition>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="modalFacturar"
      @close="modalFacturar = false; $emit('actualizar')"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Despachar
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="pt-0">
        <div class="d-flex align-items-center justify-content-end mr-3 mr-sm-2 mr-md-0">
          <small class="mr-2">
            Cambiar a completo todos
          </small>
          <div class="col-1 opcion-checkbox columna-check">
            <label>
              <input type="checkbox" v-model="despacharCompletoTodos" name="" id="">
              <span class="checkbox-icono" />
            </label>
          </div>
        </div>
        <ul
          class="table detalles-despacho"
          v-for="f in facturas"
          :key="f.id_fac"
        >
          <li class="encabezado sticky">
            <header class="h4-responsive">{{f.nom_suc}}</header>
            <div class="thead claro">
              <div class="col-12 col-sm-auto columna-dia">
                Día
                <span class="small-block">
                  Mes
                </span>
              </div>
              <div class="col-3">Cliente</div>
              <div class="col-12 col-lg-2 d-sm-none d-lg-block text-lg-right">
                Monto
                <span class="small-block">
                  Estado
                </span>
              </div>
              <div class="col">Datos de factura</div>
              <div class="col-auto columna-check">
                Cambiar a
                <span class="small-block">
                  Completo
                </span>
              </div>
            </div>
          </li>
          <li
            class="contenido"
          >
            <div
              class="col-12 col-sm-auto columna-dia"
              data-columna="Día / Mes"
            >
              <p>
                <span class="font-weight-bold">{{f.fec_fac.split('-')[2]}}</span>
                <small class="d-sm-block ml-1 ml-sm-0">{{mesEnLetra(f.fec_fac)}}</small>
              </p>
            </div>
            <div
              class="col-12 col-md-3"
              data-columna="Cliente"
            >
              {{f.nom_emp}}
              <div class="d-none d-md-block d-lg-none">
                <p>
                  <span class="font-weight-bold">Monto:</span>
                  {{f.mon_fac}}
                </p>
                <p>
                  <span class="font-weight-bold">Estado:</span>
                  {{f.est_fac}}
                </p>
              </div>
            </div>
            <div
              class="col-12 col-lg-2 d-md-none d-lg-block"
              data-columna="Monto / Estado"
            >
              <div class="text-sm-left text-md-right">
                {{f.mon_fac}}
                <span class="small-block">
                  {{f.est_fac}}
                </span>
              </div>
            </div>
            <div
              class="col py-1"
              data-columna="Datos de factura"
            >
              <div class="w-100">
                <mdb-input
                  label="Num Factura"
                  v-model="f.num_fac"
                  type="number"
                  class="my-1 mb-2"
                  outline
                />
                <mdb-input
                  label="Observaciones"
                  v-model="f.obs_fac"
                  size="sm"
                  type="textarea"
                  class="mt-2 mb-0 textarea-mb-0"
                  outline
                />
              </div>
            </div>
            <div
              class="col-auto text-sm-center opcion-checkbox columna-check"
              data-columna="Cambiar a completo">
              <label>
                <input type="checkbox" v-model="f.completo" name="" id="">
                <span class="checkbox-icono" />
              </label>
            </div>
          </li>
        </ul>
      </mdb-modal-body>
      <mdb-modal-footer class="justify-content-around">
        <mdb-btn
          flat
          dark-waves
          icon="times"
          @click="modalDetallesDespacho = false"
        >
          Cerrar
        </mdb-btn>
        <mdb-btn
          color="primario"
          icon="sync"
          @click="enviarFactura"
        >
          Actualizar
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <DatosDespacho
      :modal-detalles-despacho="detallesDespacho"
      :vigilantes="vigilantes"
      :montacarguistas="montacarguistas"
      :almacenistas="almacenistas"
      :identificador="aggDatos"
      @cerrar="detallesDespacho = false"
    />
    <ConfirmacionEliminar
      :texto-boton-confirmar="'Confirmar'"
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      :mensaje="despachoEli ? 'Se eliminará el despacho, el inventario será liberado y los pedidos volverán a la tabla de distribución' : 'Esta opción es irreversible'"
      @cerrar="modalConfirmacionEliminar = false; despachoEli = ''; despachoCom = ''"
      @confirmar="despachoEli ? eliminarDespacho() : (despachoCom ? completoDespacho() : verificarDespacho())"
    />
    <CargarCamion
      :mostrar-modal="modalCargarCamion"
      :transportistas="listaTransporte.transportistas"
      :vehiculos="listaTransporte.vehiculos"
      :carga="cargaPedido"
      :totales-por-presentacion="totalesPorPresentacion"
      :presentaciones="presentaciones && Object.keys(presentaciones).length ? presentaciones : null"
      :despachos="data.des"
      :zonas="zonas"
      :transportista-des="despachoSeleccionado.id_per"
      :vehiculo-des="despachoSeleccionado.id_veh"
      :id-des="Number(despachoSeleccionado.id_des)"
      @cerrar="modalCargarCamion = $event"
      @alerta-mensaje="alertaMensaje = $event"
      @actualizar="$emit('actualizar'); volverAlResumen()"
    />
    <OpcionesImpresion
      :mostrar-modal="opcionesDeImpresion.mostrarModal"
      :codigoDeImpresion="opcionesDeImpresion.codigoDeImpresion"
      :nombre-documento="opcionesDeImpresion.nombreDocumento"
      orientacionSugerida="landscape"
      deshabilitar-orientacion-vertical
      @cerrar="opcionesDeImpresion.mostrarModal = $event"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {
  mdbBtn,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbInput
} from 'mdbvue'
import CargarCamion from '@/components/distribucion/CargarCamion.vue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import DatosDespacho from '@/components/DatosDespacho.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import TablaDePedidos from '@/components/TablaDePedidos.vue'
import { convertirNumeroAFixed } from '@/funciones/funciones.js'
import { apiPost } from '@/funciones/api.js'
import OpcionesImpresion from '@/components/OpcionesImpresion.vue'
import { ResumenCantidadesDespacho } from '@/funciones/formatosImpresion.js'
export default {
  name: 'Despachos',
  components: {
    CargarCamion,
    DatosDespacho,
    mdbBtn,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    AlertaMensaje,
    ConfirmacionEliminar,
    TablaDePedidos,
    OpcionesImpresion
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      aggDatos: '',
      detallesDespacho: false,
      estaCargando: false,
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      deshabilitarBotonCargar: false,
      modalFacturar: false,
      despachoSeleccionado: {},
      datosPedidos: {},
      datosPedidosIm: {},
      listaTransporte: {
        transportistas: [],
        vehiculos: []
      },
      modalCargarCamion: false,
      totalesPorPresentacion: {},
      dnop: [],
      facturas: {},
      cargaPedido: {},
      maximo: {},
      despachoEli: '',
      despachoCom: '',
      despachar: '',
      despacharCompletoTodos: false,
      opcionesDeImpresion: {
        codigoDeImpresion: [],
        nombreDocumento: '',
        mostrarModal: false
      }
    }
  },
  computed: {
    despachos () {
      if (!this.data || !this.data.des) return []
      const despachos = {}
      this.data.des.forEach(c => {
        if (!despachos[c.id_des]) {
          despachos[c.id_des] = {
            id_des: c.id_des,
            nom_per: c.nom_per,
            ape_per: c.ape_per,
            id_per: c.id_per_t_des,
            cap_veh: c.cap_veh,
            pes_des: c.pes_des,
            mar_veh: c.mar_veh,
            id_veh: c.id_veh,
            mod_veh: c.mod_veh,
            col_veh: c.col_veh,
            zonas: [],
            sucursales: []
          }
        }
        if (!despachos[c.id_des].sucursales.includes(c.id_suc)) {
          despachos[c.id_des].sucursales.push(c.id_suc)
        }
        despachos[c.id_des].zonas.push({
          id_zon: this.zonas[c.id_zon_suc].id_zon,
          est_zon: this.zonas[c.id_zon_suc].est_zon
        })
      })
      return despachos
    },
    zonas () {
      if (!this.data || !this.data.zon) return {}
      const zonas = {}
      this.data.zon.forEach(z => {
        if (!zonas[z.id_zon]) {
          zonas[z.id_zon] = {
            id_zon: z.id_zon,
            est_zon: z.est_zon,
            ciu_zon: z.ciu_zon,
            sec_zon: z.sec_zon
          }
        }
      })
      return zonas
    },
    despachosHoy () {
      if (!this.data || !this.data.desh) return []
      const despachosHoy = {}
      this.data.desh.forEach(d => {
        if (!despachosHoy[d.id_des]) {
          despachosHoy[d.id_des] = {
            nom_per: d.nom_per,
            ape_per: d.ape_per,
            id_des: d.id_des,
            clientes: {}
          }
        }
        despachosHoy[d.id_des].clientes[d.id_suc] = {
          nom_emp: d.nom_emp,
          est_zon: d.est_zon,
          nom_suc: d.nom_suc
        }
      })
      return despachosHoy
    },
    despachosHoyCantidad () {
      if (!this.data || !this.data.rdesh || !this.data.rdeshp) return []
      const despachosHoyCantidades = {}
      const tra = []
      const veh = []
      const dhcp = []
      const estadosTodos = []
      const clientes = {}
      let vendedores = ''

      this.data.desh.forEach((desh) => {
        if (!clientes[desh.id_suc]) {
          clientes[desh.id_suc] = desh.id_suc
        }
      })
      despachosHoyCantidades.clientes = Object.keys(clientes).length

      this.data.rdesh.forEach((desh) => {
        tra.push(desh.tra_nombre)
        vendedores = desh.ven_nombre
        veh.push(`${desh.mar_veh} ${desh.mod_veh} ${desh.col_veh} - ${desh.pla_veh}`)
      })

      const tra2 = tra.filter(function (ele, pos) {
        return tra.indexOf(ele) === pos
      })
      despachosHoyCantidades.transportistas = tra2.join(', ') + '.'
      const veh2 = veh.filter(function (ele, pos) {
        return veh.indexOf(ele) === pos
      })
      despachosHoyCantidades.vehiculos = veh2.join(', ') + '.'
      despachosHoyCantidades.vendedores = vendedores + '.'

      despachosHoyCantidades.productos = {}
      despachosHoyCantidades.totales = {
        peso: 0,
        cantidad: 0
      }
      this.data.rdeshp.forEach((deshp) => {
        const est = deshp.est_pro.split(',')
        est.forEach((e) => {
          dhcp.push(e)
        })
        despachosHoyCantidades.productos[deshp.cod_pre] = {
          categoria: deshp.nom_cat,
          codigo: deshp.cod_pre,
          producto: deshp.nom_pro,
          presentacion: deshp.des_pre,
          peso: Number(deshp.pesm_pre).toFixed(2),
          pesoTotal: Number((deshp.can_sap * Number(deshp.pesm_pre)).toFixed(2)),
          cantidad: deshp.can_sap,
          estados: deshp.est_pro,
          clientes: deshp.sucursales.split(',').length
        }
        despachosHoyCantidades.totales.peso = despachosHoyCantidades.totales.peso + Number((deshp.can_sap * Number(deshp.pesm_pre)).toFixed(2))
        despachosHoyCantidades.totales.cantidad = despachosHoyCantidades.totales.cantidad + Number(deshp.can_sap)
      })

      for (const pro in dhcp) {
        if (!estadosTodos.includes(dhcp[pro])) {
          estadosTodos.push(dhcp[pro])
        }
      }
      despachosHoyCantidades.estados = estadosTodos.join(', ') + '.'

      return despachosHoyCantidades
    },
    categorias () {
      if (!this.data || !this.data.cat) return {}
      const categorias = {}
      const productos = {}
      this.data.cat.forEach((c) => {
        categorias[c.id_cat] = c
        categorias[c.id_cat].productos = {}
      })
      this.data.pro.forEach((p) => {
        productos[p.id_pro] = p
        productos[p.id_pro].presentaciones = {}
      })
      this.data.pre.forEach((p) => {
        productos[p.id_pro_pre].presentaciones[p.id_pre] = p
      })
      for (const p in productos) {
        if (Object.keys(productos[p].presentaciones).length) {
          categorias[productos[p].id_cat_pro].productos[productos[p].id_pro] = productos[p]
        }
      }
      for (const c in categorias) {
        if (!Object.keys(categorias[c].productos).length) {
          delete categorias[c]
        }
      }
      return categorias
    },
    montacarguistas () {
      return this.data.mnt
    },
    vigilantes () {
      return this.data.vig
    },
    almacenistas () {
      return this.data.alm
    },
    presentaciones () {
      if (!this.data || !this.data.pre) return {}
      const presentaciones = {}
      this.data.pre.forEach((p) => {
        presentaciones[p.id_pre] = p
      })
      return presentaciones
    },
    notasPorPedido () {
      if (!this.dnop.length) return {}
      const notasPorPedido = {}
      this.dnop.forEach((np) => {
        if (!notasPorPedido[np.id_ped_np]) {
          notasPorPedido[np.id_ped_np] = {}
        }
        if (!notasPorPedido[np.id_ped_np][np.id_pre_np]) {
          notasPorPedido[np.id_ped_np][np.id_pre_np] = {}
        }
        if (notasPorPedido[np.id_ped_np][np.id_pre_np].cantidad) {
          notasPorPedido[np.id_ped_np][np.id_pre_np].cantidad += Number(np.can_np)
        } else {
          notasPorPedido[np.id_ped_np][np.id_pre_np].cantidad = Number(np.can_np)
        }
        notasPorPedido[np.id_ped_np][np.id_pre_np].inventario = np.can_ni ? np.can_ni : 0
      })
      return notasPorPedido
    },
    totalesPorPresentacionPedidos () {
      if (!this.dnop.length) return {}
      const totales = {
        peso: 0,
        cantidad: 0
      }
      this.dnop.forEach((np) => {
        if (this.presentaciones[np.id_pre_np]) {
          if (!totales[np.id_pre_np]) {
            totales[np.id_pre_np] = {
              peso: 0,
              cantidad: 0
            }
          }
          const cantidad = Number(np.can_np)
          const peso = Number((Number(this.presentaciones[np.id_pre_np].pesm_pre) * cantidad).toFixed(2))

          totales[np.id_pre_np].cantidad += cantidad
          totales[np.id_pre_np].peso += peso

          totales.peso += peso
          totales.cantidad += cantidad
        }
      })
      return totales
    },
    nuevos () {
      const nuevos = {
        porPed: {},
        porSuc: {}
      }
      if (!this.data || !this.data.nuevos) return nuevos
      this.data.nuevos.forEach((n) => {
        nuevos.porPed[n.id_ped] = true
        nuevos.porSuc[n.id_suc_ped] = true
      })
      return nuevos
    }
  },
  methods: {
    imprimirResumenCantidades () {
      const PRODUCTOS = [
        [
          {
            text: 'CÓDIGO',
            fontSize: '10',
            bold: true
          },
          {
            text: 'DESCRIPCIÓN',
            fontSize: '10',
            bold: true
          },
          {
            text: 'ESTADOS',
            alignment: 'center',
            fontSize: '10',
            bold: true
          },
          {
            text: 'CLIENTES',
            fontSize: '10',
            alignment: 'right',
            bold: true
          },
          {
            stack: [
              {
                text: 'TOTAL PESO',
                fontSize: '10',
                alignment: 'right',
                bold: true
              },
              {
                text: 'CANTIDAD',
                fontSize: 7,
                alignment: 'right'
              }
            ]
          }
        ]
      ]
      for (const pro in this.despachosHoyCantidad.productos) {
        PRODUCTOS.push([
          {
            text: this.despachosHoyCantidad.productos[pro].codigo,
            fontSize: 11
          },
          {
            text: this.despachosHoyCantidad.productos[pro].producto + ' ' + this.despachosHoyCantidad.productos[pro].presentacion,
            fontSize: 11
          },
          {
            text: this.despachosHoyCantidad.productos[pro].estados,
            fontSize: 11,
            alignment: 'center'
          },
          {
            text: this.despachosHoyCantidad.productos[pro].clientes,
            fontSize: 11,
            alignment: 'right'
          },
          {
            stack: [
              {
                text: `${this.despachosHoyCantidad.productos[pro].pesoTotal} kg`,
                fontSize: 11,
                alignment: 'right'
              },
              {
                text: this.despachosHoyCantidad.productos[pro].cantidad,
                fontSize: 7,
                alignment: 'right'
              }
            ]
          }
        ])
      }
      PRODUCTOS.push([
        {
          text: 'TOTALES',
          fontSize: 11,
          bold: true
        },
        {},
        {},
        {
          text: this.despachosHoyCantidad.totales.cantidad,
          alignment: 'right',
          fontSize: 11,
          bold: true
        },
        {
          text: `${Number(this.despachosHoyCantidad.totales.peso.toFixed(2))} kg`,
          alignment: 'right',
          fontSize: 11,
          bold: true
        }
      ])
      const { nombre, formato } = ResumenCantidadesDespacho(this.despachosHoyCantidad, PRODUCTOS)

      this.opcionesDeImpresion = {
        codigoDeImpresion: formato,
        nombreDocumento: nombre,
        mostrarModal: true
      }
    },
    volverAlResumen () {
      this.despachoSeleccionado = {}
    },
    eliminarPedido (e) {
      this.cargaPedido = JSON.parse(JSON.stringify(this.cargaPedido))
      this.datosPedidos = JSON.parse(JSON.stringify(this.datosPedidos))
      delete this.cargaPedido[e]
      delete this.datosPedidos[e]
      delete this.notasPorPedido[e]

      this.calcularTotales()
    },
    eliminarPresentacion (e) {
      for (const cp in this.cargaPedido) {
        if (this.cargaPedido[cp][e]) {
          this.cargaPedido = JSON.parse(JSON.stringify(this.cargaPedido))
          this.cargaPedido[cp][e] = '0'
        }
      }
      for (const dp in this.datosPedidos) {
        if (this.datosPedidos[dp].notas[e]) {
          this.datosPedidos = JSON.parse(JSON.stringify(this.datosPedidos))
          this.datosPedidos[dp].notas[e] = '0'
        }
      }
      for (const np in this.notasPorPedido) {
        if (this.notasPorPedido[np][e]) {
          this.notasPorPedido[np][e] = '0'
        }
      }
      this.calcularTotales()
    },
    eliminarDespacho () {
      apiPost({ s: 'eliDesM' }, 'des=' + this.despachoEli)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.despachoEli = ''
            this.$emit('actualizar')
          } else {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema',
              tipo: 'error'
            }
            this.despachoEli = ''
          }
        })
    },
    completoDespacho () {
      apiPost({ s: 'pedDesComplMv' }, 'des=' + this.despachoCom)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: res.data.e,
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.despachoCom = ''
            this.$emit('actualizar')
          } else {
            this.alertaMensaje = {
              contenido: res.data.e,
              tipo: 'error'
            }
            this.despachoCom = ''
          }
        })
    },
    seleccionarTodos (idPe, checked) {
      for (const p in this.cargaPedido[idPe]) {
        this.calcularTotales(checked ? this.cargaPedido[idPe][p] = this.notasPorPedido[idPe][p]?.cantidad : this.cargaPedido[idPe][p] = '')
      }
    },
    mostrarZona (idSuc) {
      return this.zonas[idSuc].est_zon
    },
    mostrarZonasDespacho (zona, des) {
      let zonas = ''
      let coma = ''
      const contador = {}
      this.data.des.forEach(c => {
        if (des === c.id_des) {
          if (!contador[this.zonas[c.id_zon_suc].est_zon]) {
            contador[this.zonas[c.id_zon_suc].est_zon] = []
          }
          if (!contador[this.zonas[c.id_zon_suc].est_zon].includes(c.id_suc)) {
            contador[this.zonas[c.id_zon_suc].est_zon].push(c.id_suc)
          }
        }
      })
      for (const i in zona) {
        if (!zonas.includes(zona[i].est_zon)) {
          zonas += coma
          coma = ', '
          zonas += `${zona[i].est_zon}(${contador[zona[i].est_zon]?.length})`
        }
      }
      return zonas
    },
    convertirValorAFixed (numero) {
      return convertirNumeroAFixed(numero)
    },
    mesEnLetra (fechaPlana) {
      var mess = new Date(fechaPlana.substring(5, 7))
      var opt = { month: 'short' }
      var mesLetras = mess.toLocaleDateString('es-VE', opt)
      return mesLetras
    },
    verificarDespacho () {
      if (!this.vigilanteSeleccionado.id_per || !this.montacarguistaSeleccionado.id_per || !this.almacenistaSeleccionado.id_per) {
        this.alertaMensaje = {
          contenido: 'Debes llenar todos los datos del despacho',
          tipo: 'error'
        }
        return
      }
      apiPost({ s: 'desDisMv' }, 'fec=' + this.fechaSalida + '&fecr=' + this.fechaRetorno + '&desp=' + this.despachar)
        .then((res) => {
          this.facturas = res.data.fac
        })
      this.modalConfirmacionEliminar = false
      this.modalFacturar = true
    },
    verDetallesDespacho (d) {
      this.datosPedidos = {}
      this.cargaPedido = {}
      this.estaCargando = true
      return new Promise((resolve, reject) => {
        apiPost({ s: 'desEditallesMv' }, 'des=' + d)
          .then((res) => {
            this.dnop = res.data.dnop
            res.data.peddes.forEach((p) => {
              this.datosPedidos[p.id_ped] = p
              this.datosPedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            })
            res.data.desdet.forEach((p) => {
              this.fechaSalida = p.fec_des
              this.fechaRetorno = p.fec_ret_des
              this.observaciones = p.obs_des
              this.vigilanteSeleccionado = {
                id_per: p.v_id_per,
                nom_per: p.v_nom_per,
                ape_per: p.v_ape_per
              }
              this.montacarguistaSeleccionado = {
                id_per: p.m_id_per,
                nom_per: p.m_nom_per,
                ape_per: p.m_ape_per
              }
              this.almacenistaSeleccionado = {
                id_per: p.a_id_per,
                nom_per: p.a_nom_per,
                ape_per: p.a_ape_per
              }
            })
            res.data.dsap.forEach((p) => {
              if (!this.cargaPedido[p.id_ped_np]) {
                this.cargaPedido[p.id_ped_np] = {}
              }
              if (!this.cargaPedido[p.id_ped_np][p.id_pre_np]) {
                this.cargaPedido[p.id_ped_np][p.id_pre_np] = p.can_sap
                if (!this.maximo[p.id_pre_np]) {
                  this.maximo[p.id_pre_np] = 0
                }
                this.maximo[p.id_pre_np] += Number(p.can_sap)
              }
              this.calcularTotales()
            })
            resolve()
            this.estaCargando = false
          }).catch(err => {
            this.estaCargando = false
            reject(err)
          })
      })
    },
    enviarFactura () {
      this.facturas.map((f) => {
        f.completo = f.completo ? 'c' : ''
        f.num_fac = f.num_fac ? f.num_fac : ''
        f.obs_fac = f.obs_fac ? f.obs_fac : ''
      })
      let data = ''
      for (const fac in this.facturas) {
        data += 'fac[]=' + this.facturas[fac].id_fac + '&ped[]=' + this.facturas[fac].id_ped_np + '&est[]=' + this.facturas[fac].completo + '&num[]=' + this.facturas[fac].num_fac + '&obsf[]=' + this.facturas[fac].obs_fac + '&'
      }
      apiPost({ s: 'setFacMv' }, data)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.modalFacturar = false
            this.$emit('actualizar')
          } else {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema',
              tipo: 'error'
            }
          }
        })
    },
    cargarAlCamion (zonaId) {
      this.zona = zonaId
      this.listaTransporte = {
        transportistas: this.data.cho,
        vehiculos: this.data.veh
      }
      this.modalCargarCamion = true
    },
    calcularTotales () {
      this.deshabilitarBotonCargar = false
      const totales = {
        peso: 0,
        volumen: 0,
        cantidad: 0
      }
      for (const pedido in this.cargaPedido) {
        for (const presentacion in this.cargaPedido[pedido]) {
          if (this.presentaciones[presentacion]) {
            if (!totales[presentacion]) {
              totales[presentacion] = {
                cantidad: 0,
                peso: 0,
                volumen: 0
              }
            }
            const cantidad = Number(this.cargaPedido[pedido][presentacion])
            const volumen = Number((cantidad * Number(this.presentaciones[presentacion].volm_pre)).toFixed(2))
            const peso = Number((cantidad * Number(this.presentaciones[presentacion].pesm_pre)).toFixed(2))
            totales[presentacion].cantidad += cantidad
            totales[presentacion].peso += peso
            totales[presentacion].volumen += Number((cantidad * Number(this.presentaciones[presentacion].vol_pre)).toFixed(2))

            totales.peso += peso
            totales.volumen += volumen
            totales.cantidad += cantidad
          }
        }
      }
      this.totalesPorPresentacion = totales
    },
    async imprimir (tipo, des) {
      try {
        await this.verDetallesDespacho(des)
        await this.abrirVentana(tipo, des)
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un problema obteniendo los detalles del despacho',
          tipo: 'error'
        }
      }
    },
    abrirVentana (impresion, id) {
      if (!this.vigilanteSeleccionado.id_per || !this.fechaSalida || !this.fechaRetorno || !this.montacarguistaSeleccionado.id_per || !this.almacenistaSeleccionado.id_per) {
        this.alertaMensaje = {
          contenido: 'Debes rellenar todos los datos de la impresión',
          tipo: 'error'
        }
        return
      }
      const routeData = this.$router.resolve({ name: impresion, params: { id: id } })
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    despacharCompletoTodos () {
      if (this.despacharCompletoTodos) {
        this.facturas.forEach((f) => {
          f.completo = true
        })
      } else {
        this.facturas.forEach((f) => {
          f.completo = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  cursor: default;
}
.table.despachos,
.table.detalles-despacho {
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("768px");
}

// Tabla Despachos breakpoints
.table.despachos {
  margin: 0 auto 3rem;

  // Define la altura del sticky
  .encabezado.sticky {
    top: 122px;

    // Por cambio de height en menú secundario
    @media screen and (min-width: 397px) {top: 88px;}
    @media screen and (min-width: 768px) {top: 57px;}
  }

  .btn-dropdown-100 button {
    width: 100%;
  }
  .detalle-despacho.titulo {
    color: #2c3e50;
    font-weight: bold;
  }
}
.opcion-checkbox{
  @include agregar-checkbox(1.75rem);
}

.columna-check {
  @media screen and (min-width:576px) {
    width: 94px;
  }
}
.columna-dia{
  @media screen and (min-width:576px) {
    width: 64px
  }
}
.md-form.md-outline textarea.md-textarea {
  margin-bottom: 0 !important;
}
.bold {
  font-weight: 400;
}
</style>
