<template>
  <!-- Modal para las opciones de impresión -->
  <mdb-modal
    centered
    elegant
    scrollable
    :show="mostrarModal"
  >
    <mdb-modal-header :close="false">
      <mdb-modal-title>
        Opciones de impresión
      </mdb-modal-title>
      <a
        class="close"
        @click="$emit('cerrar', false);"
      >×</a>
    </mdb-modal-header>
    <mdb-modal-body class="pt-0">
      <header class="font-weight-bold mb-2">
        Tamaño / Orientación de hoja
      </header>
      <div class="row justify-content-around mt-n2 mt-sm-0 mb-sm-2">
        <div :class="['col-12 col-sm-4 py-1 py-sm-0 opciones-radio', {'activo': orientacion === 'landscape'}]">
          <label>
            <input
              v-model="orientacion"
              type="radio"
              name="radio-orientacion"
              value="landscape"
              checked
            >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="icono">
              <rect y="2" width="20" height="16" rx="3" fill="#2C3E50"/>
              <path d="M10 10.5C11.2422 10.5 12.25 9.49219 12.25 8.25C12.25 7.00781 11.2422 6 10 6C8.75781 6 7.75 7.00781 7.75 8.25C7.75 9.49219 8.75781 10.5 10 10.5ZM12 11H11.1391C10.7922 11.1594 10.4062 11.25 10 11.25C9.59375 11.25 9.20938 11.1594 8.86094 11H8C6.89531 11 6 11.8953 6 13V13.25C6 13.6641 6.33594 14 6.75 14H13.25C13.6641 14 14 13.6641 14 13.25V13C14 11.8953 13.1047 11 12 11Z" fill="white"/>
            </svg>
            <span class="opcion-nombre">
              Horizontal
              <small title="Tamaño horizontal automático">
                auto
              </small>
            </span>
          </label>
        </div>
        <div v-if="!deshabilitarOrientacionVertical" :class="['col-12 col-sm-4 py-1 py-sm-0 opciones-radio', {'activo': orientacion === 'portrait'}]">
          <label>
            <input
              v-model="orientacion"
              type="radio"
              name="radio-orientacion"
              value="portrait"
            >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="icono">
              <path class="fondo" d="M2 3C2 1.34315 3.34315 0 5 0H15C16.6569 0 18 1.34315 18 3V17C18 18.6569 16.6569 20 15 20H5C3.34315 20 2 18.6569 2 17V3Z" fill="#2C3E50"/>
              <path d="M10 10.5C11.2422 10.5 12.25 9.49219 12.25 8.25C12.25 7.00781 11.2422 6 10 6C8.75781 6 7.75 7.00781 7.75 8.25C7.75 9.49219 8.75781 10.5 10 10.5ZM12 11H11.1391C10.7922 11.1594 10.4062 11.25 10 11.25C9.59375 11.25 9.20938 11.1594 8.86094 11H8C6.89531 11 6 11.8953 6 13V13.25C6 13.6641 6.33594 14 6.75 14H13.25C13.6641 14 14 13.6641 14 13.25V13C14 11.8953 13.1047 11 12 11Z" fill="white"/>
            </svg>
            <span class="opcion-nombre">
              Vertical
              <small title="Tamaño vertical automático">
                auto
              </small>
            </span>
          </label>
        </div>
        <div :class="['col-12 col-sm-4 py-1 py-sm-0 opciones-radio', {'activo': orientacion === 'personalizada'}]">
          <label>
            <input
              v-model="orientacion"
              type="radio"
              name="radio-orientacion"
              value="personalizada"
            >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="icono">
              <rect x="4" y="4" width="14" height="14" rx="3" fill="#2C3E50"/>
              <path d="M11 11.375C11.9316 11.375 12.6875 10.6191 12.6875 9.6875C12.6875 8.75586 11.9316 8 11 8C10.0684 8 9.3125 8.75586 9.3125 9.6875C9.3125 10.6191 10.0684 11.375 11 11.375ZM12.5 11.75H11.8543C11.5941 11.8695 11.3047 11.9375 11 11.9375C10.6953 11.9375 10.407 11.8695 10.1457 11.75H9.5C8.67148 11.75 8 12.4215 8 13.25V13.4375C8 13.748 8.25195 14 8.5625 14H13.4375C13.748 14 14 13.748 14 13.4375V13.25C14 12.4215 13.3285 11.75 12.5 11.75Z" fill="white"/>
              <path class="medida" d="M4 3V2L18 2V3" stroke="#2C3E50" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="medida" d="M3 4H2L2 18H3" stroke="#2C3E50" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="opcion-nombre">
              Personalizada
            </span>
          </label>
        </div>
      </div>
      <transition
        name="inputs-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
        :duration="{ enter: 550, leave: 250 }"
      >
        <div
          v-if="orientacion === 'personalizada'"
          class="row"
        >
          <div class="col-12 col-sm-6">
            <mdb-input
              v-model.number="print.height"
              class="my-1 my-sm-2"
              label="Alto"
              type="number"
              :min="0"
              outline
            >
              <span
                slot="append"
                class="input-group-text md-addon px-2"
              >
                mm
              </span>
            </mdb-input>
          </div>
          <div class="col-12 col-sm-6">
            <mdb-input
              v-model.number="print.width"
              class="my-1 my-sm-2"
              label="Ancho"
              type="number"
              :min="0"
              outline
            >
              <span
                slot="append"
                class="input-group-text md-addon px-2"
              >
                mm
              </span>
            </mdb-input>
          </div>
        </div>
      </transition>
      <header class="font-weight-bold mt-3 mb-2">
        Márgenes
      </header>
      <div class="row">
        <div class="col-12 col-sm-6">
          <mdb-input
            v-model.number="print.mt"
            type="number"
            :min="0"
            class="my-1 my-sm-2"
            label="Superior"
            outline
          >
            <span
              slot="append"
              class="input-group-text md-addon px-2"
            >
              mm
            </span>
          </mdb-input>
        </div>
        <div class="col-12 col-sm-6">
          <mdb-input
            v-model.number="print.mb"
            type="number"
            :min="0"
            class="my-1 my-sm-2"
            label="Inferior"
            outline
          >
            <span
              slot="append"
              class="input-group-text md-addon px-2"
            >
              mm
            </span>
          </mdb-input>
        </div>
        <div class="col-12 col-sm-6">
          <mdb-input
            v-model.number="print.ml"
            type="number"
            :min="0"
            class="my-1 my-sm-2"
            label="Izquierdo"
            outline
          >
            <span
              slot="append"
              class="input-group-text md-addon px-2"
            >
              mm
            </span>
          </mdb-input>
        </div>
        <div class="col-12 col-sm-6">
          <mdb-input
            v-model.number="print.mr"
            type="number"
            :min="0"
            class="my-1 my-sm-2"
            label="Derecho"
            outline
          >
            <span
              slot="append"
              class="input-group-text md-addon px-2"
            >
              mm
            </span>
          </mdb-input>
        </div>
      </div>
      <div class="row justify-content-around mt-3">
        <mdb-btn
          ref="btnEmitClose"
          flat
          dark-waves
          icon="times"
          @click="$emit('cerrar', false);"
        >
          Cerrar
        </mdb-btn>
        <mdb-btn
          ref="btnEmitImprimir"
          type="submit"
          color="primario"
          icon="print"
          @click="imprimirVista(); $emit('cerrar', false);"
        >
          Imprimir
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>
<script>
import {
  mdbBtn, mdbInput, mdbModal, mdbModalHeader, mdbModalTitle,
  mdbModalBody
} from 'mdbvue'

// PDFmake
const pdfMake = require('pdfmake/build/pdfmake.js')
const pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'OpcionesImpresion',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody
  },
  props: {
    codigoDeImpresion: {
      type: Array,
      required: true
    },
    mostrarModal: {
      type: Boolean,
      required: true
    },
    nombreDocumento: {
      type: String,
      required: true
    },
    orientacionSugerida: {
      type: String,
      default: ''
    },
    deshabilitarOrientacionVertical: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orientacion: 'portrait',
      print: {
        mt: 15,
        mr: 15,
        mb: 15,
        ml: 15,
        width: 0
      }
    }
  },
  watch: {
    mostrarModal: function () {
      if (this.mostrarModal) {
        if (this.orientacionSugerida.length) {
          this.orientacion = this.orientacionSugerida
        }
      } else {
        this.limpiarCampos()
      }
    },
    orientacion: function () {
      if (this.orientacion === 'personalizada') {
        this.print.width = ''
        this.print.height = ''
      } else {
        this.print.width = 0
        this.print.height = 0
      }
    }
  },
  methods: {
    // Conversor de mm a cm
    conversorDeUnidad (mm) {
      const cm = mm / 10
      const pts = cm / 2.54 * 72 // Fórmula que convierte de cm a pt
      return pts
    },
    limpiarCampos () {
      this.orientacion = 'portrait'
      this.print = {
        mt: 15,
        mr: 15,
        mb: 15,
        ml: 15,
        width: 0
      }
    },
    imprimirVista () {
      // Variable de impresión
      const docDefinition = {
        info: {
          title: this.nombreDocumento,
          author: 'Industrias KEL C. A.'
        },
        content: JSON.parse(JSON.stringify(this.codigoDeImpresion)),
        styles: {
          header1: {
            fontSize: 16,
            bold: true
          },
          header2: {
            fontSize: 15,
            bold: true
          },
          header3: {
            fontSize: 14
          },
          header4: {
            fontSize: 13
          },
          header5: {
            fontSize: 12,
            bold: true
          },
          header6: {
            fontSize: 11,
            bold: true
          },
          parrafo1: {
            fontSize: 9
          }
        },
        defaultStyle: {
          columnGap: 10,
          fontSize: 10
        },
        pageSize: this.print.width
          ? {
            width: this.conversorDeUnidad(this.print.width),
            height: this.conversorDeUnidad(this.print.height)
          }
          : 'LETTER',
        pageOrientation: this.orientacion === 'landscape' ||
          this.orientacion === 'portrait'
          ? this.orientacion
          : 'auto',
        pageMargins: [
          this.conversorDeUnidad(this.print.ml),
          this.conversorDeUnidad(this.print.mt),
          this.conversorDeUnidad(this.print.mr),
          this.conversorDeUnidad(this.print.mb)
        ]
      }
      pdfMake.createPdf(docDefinition).open()
    }
  }
}
</script>

<style lang="scss" scoped>
.opciones-radio {
  label {
    align-items: center;
    border: 1px solid $gris-tr-borde;
    border-radius: .5rem;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    margin-bottom: 0;
    padding: .75rem;
    width: 100%;
    @include agregar-prefijos(transition, border-color .5s ease);

    @media screen and (min-width: 576px) {
      flex-direction: column;
      justify-content: center;
      padding: .8rem 0;
    }

    input[type="radio"] {display: none;}

    .icono {
      height: 30px;
      width: 30px;

      @media screen and (min-width: 576px) {
        height: 50px;
        width: 50px;
      }

      rect,
      path.fondo {
        @include agregar-prefijos(transition, fill .5s ease);
      }
      path.medida {
        @include agregar-prefijos(transition, stroke .5s ease);
      }
    }

    .opcion-nombre {
      font-size: .85rem;
      margin-left: .5rem;
      @include agregar-prefijos(transition, color .5s ease);

      @media screen and (min-width: 576px) {
        margin: .5rem 0 0;
      }
    }
  }

  &.activo label {
    // Define el color del activo
    $color-activo: $primario;

    border-color: $color-activo;

    .icono rect,
    .icono path.fondo {
      fill: $color-activo;
    }
    .icono  path.medida {
      stroke: $color-activo;
    }

    .opcion-nombre {
      color: $color-activo;
      font-weight: 600;
    }
  }
}
</style>
