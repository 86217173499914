<template>
<section>
  <mdb-modal
    centered
    elegant
    scrollable
    size="lg"
    :show="mostrar"
    @close="mostrar = false, $emit('cerrar')"
  >
    <mdb-modal-header>
      <mdb-modal-title>Datos del despacho</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="row pt-0">
      <header class="col-12 h4-responsive detalle-despacho titulo mb-2">Fechas del despacho</header>
      <div class="col-12 col-sm-6">
        <mdb-input
          v-model="fechaSalida"
          type="date"
          label="Fecha de salida"
          class="my-1"
          outline
        />
      </div>
      <div class="col-12 col-sm-6">
        <mdb-input
          v-model="fechaRetorno"
          type="date"
          label="Fecha de retorno"
          class="my-1"
          outline
        />
      </div>
      <header class="col-12 h4-responsive detalle-despacho titulo mb-2">Personal encargado</header>
      <div class="col-12 col-sm-6 col-lg-4 mt-2">
        <div class="md-form md-outline outline-select my-1">
          <select
            :id="`select-vigilante-${identificador}`"
            v-model="vigilanteSeleccionado"
            class="custom-select"
          >
            <option class="d-none" disabled :value="{}">Seleccione</option>
            <option
              v-for="vigilante in vigilantes"
              :key="vigilante.id_per"
              :value="vigilante"
            >
              {{vigilante.nom_per}} {{vigilante.ape_per}}
            </option>
          </select>
          <label
            :for="`select-vigilante-${identificador}`"
            :class="Object.values(vigilanteSeleccionado).length
              ? 'label-active'
              : 'label-inactive'"
          >
            Vigilante
          </label>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 mt-2">
        <div class="md-form md-outline outline-select my-1">
          <select
            :id="`select-montacarguista-${identificador}`"
            v-model="montacarguistaSeleccionado"
            class="custom-select"
          >
            <option class="d-none" disabled :value="{}">Seleccione</option>
            <option
              v-for="montacarguista in montacarguistas"
              :key="montacarguista.id_per"
              :value="montacarguista"
            >
              {{montacarguista.nom_per}} {{montacarguista.ape_per}}
            </option>
          </select>
          <label
            :for="`select-montacarguista-${identificador}`"
            :class="Object.values(montacarguistaSeleccionado).length
              ? 'label-active'
              : 'label-inactive'"
          >
            Montacarguista
          </label>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 mt-2">
        <div class="md-form md-outline outline-select my-1">
          <select
            :id="`select-almacenista-${identificador}`"
            v-model="almacenistaSeleccionado"
            class="custom-select"
          >
            <option class="d-none" disabled :value="{}">Seleccione</option>
            <option
              v-for="almacenista in almacenistas"
              :key="almacenista.id_per"
              :value="almacenista"
            >
              {{almacenista.nom_per}} {{almacenista.ape_per}}
            </option>
          </select>
          <label
            :for="`select-almacenista-${identificador}`"
            :class="Object.values(almacenistaSeleccionado).length
              ? 'label-active'
              : 'label-inactive'"
          >
            Almacenista
          </label>
        </div>
      </div>
      <div class="col-12">
        <mdb-input
          label="Observaciones"
          v-model="observaciones"
          type="textarea"
          class="mt-2 mb-0 textarea-mb-0"
          outline
        />
      </div>
      <div class="col-12 d-flex justify-content-around mt-2">
        <mdb-btn
          flat
          dark-waves
          icon="times"
          @click="mostrar = false, $emit('cerrar')"
        >
          Cerrar
        </mdb-btn>
        <mdb-btn
          color="primario"
          :icon="notaDeEntrega ? 'check' :'sync'"
          @click="guardarDatos()"
        >
          {{notaDeEntrega ? 'Guardar' :'Actualizar'}}
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
  <AlertaMensaje
    :alerta-mensaje="alertaMensaje"
    @cerrar="alertaMensaje.contenido = ''"
  />
</section>
</template>
<script>
import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbInput
} from 'mdbvue'
import { apiPost } from '@/funciones/api.js'
import AlertaMensaje from '@/components/AlertaMensaje.vue'

export default {
  name: 'DatosDespacho',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    AlertaMensaje,
    mdbInput
  },
  props: {
    identificador: {
      type: [String, Number],
      required: true
    },
    modalDetallesDespacho: {
      type: Boolean,
      default: false
    },
    notaDeEntrega: {
      type: Boolean,
      default: false
    },
    vigilantes: {
      type: Array,
      default: () => []
    },
    montacarguistas: {
      type: Array,
      default: () => []
    },
    almacenistas: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      aggDatosPersonal: {},
      mostrar: false,
      fechaSalida: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2) + '-' + ('0' + (new Date().getDate())).substr(-2),
      fechaRetorno: '',
      vigilanteSeleccionado: {},
      montacarguistaSeleccionado: {},
      almacenistaSeleccionado: {},
      observaciones: '',
      alertaMensaje: { contenido: '' }
    }
  },
  watch: {
    modalDetallesDespacho () {
      if (this.modalDetallesDespacho) {
        this.verDetallesDespacho()
      }
    },
    almacenistaSeleccionado () {
      this.modalDetallesDes()
    }
  },
  methods: {
    verDetallesDespacho () {
      if (this.modalDetallesDespacho) {
        apiPost({ s: 'desEditallesMv' }, 'des=' + this.identificador)
          .then((res) => {
            res.data.desdet.forEach((p) => {
              this.fechaSalida = p.fec_des || new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2) + '-' + ('0' + (new Date().getDate())).substr(-2)
              this.fechaRetorno = p.fec_ret_des || ''
              this.observaciones = p.obs_des || ''
              if (p.v_id_per) {
                this.vigilanteSeleccionado = {
                  id_per: p.v_id_per,
                  nom_per: p.v_nom_per,
                  ape_per: p.v_ape_per
                }
              } else if (this.vigilantes.length === 1) {
                this.vigilanteSeleccionado = this.vigilantes[0]
              }
              if (p.m_id_per) {
                this.montacarguistaSeleccionado = {
                  id_per: p.m_id_per,
                  nom_per: p.m_nom_per,
                  ape_per: p.m_ape_per
                }
              } else if (this.montacarguistas.length === 1) {
                this.montacarguistaSeleccionado = this.montacarguistas[0]
              }
              if (p.a_id_per) {
                this.almacenistaSeleccionado = {
                  id_per: p.a_id_per,
                  nom_per: p.a_nom_per,
                  ape_per: p.a_ape_per
                }
              } else if (this.almacenistas.length === 1) {
                this.almacenistaSeleccionado = this.almacenistas[0]
              }
            })
          })
      }
    },
    modalDetallesDes () {
      if (this.notaDeEntrega && this.vigilanteSeleccionado.id_per && this.fechaSalida && this.fechaRetorno && this.montacarguistaSeleccionado.id_per && this.almacenistaSeleccionado.id_per) {
        this.mostrar = false
        this.$emit('cerrar')
        this.abrirVentana()
        return
      }
      this.mostrar = this.modalDetallesDespacho
    },
    abrirVentana () {
      const routeData = this.$router.resolve({ name: 'NotaDeEntrega', params: { id: this.identificador } })
      window.open(routeData.href, '_blank')
    },
    guardarDatos () {
      if (!this.vigilanteSeleccionado.id_per || !this.fechaSalida || !this.fechaRetorno || !this.montacarguistaSeleccionado.id_per || !this.almacenistaSeleccionado.id_per) {
        this.alertaMensaje = {
          contenido: 'Debes rellenar todos los datos',
          tipo: 'error'
        }
        return
      }
      apiPost({ s: 'datosDes' }, 'des=' + this.identificador + '&ret=' + this.fechaRetorno + '&sa=' + this.fechaSalida + '&al=' + this.almacenistaSeleccionado.id_per + '&mon=' + this.montacarguistaSeleccionado.id_per + '&vi=' + this.vigilanteSeleccionado.id_per + '&obs=' + this.observaciones)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Se han guardado los datos',
              tipo: 'correcto'
            }
            this.aggDatos = false
            this.verDetallesDespacho(this.identificador)
            this.$emit('actualizar')
            if (this.notaDeEntrega) {
              this.abrirVentana()
            }
            this.mostrar = false
            this.$emit('cerrar')
          } else {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema guardando los datos',
              tipo: 'error'
            }
          }
        })
    }
  },
  mounted () {
    this.verDetallesDespacho()
  }
}
</script>
