import Vue from 'vue'
import VueRouter from 'vue-router'
import Distribucion from '../views/Distribucion.vue'
import Clientes from '../views/Clientes.vue'
import Cliente from '../views/Cliente.vue'
import Login from '../views/Login.vue'
import Restricciones from '../views/Restricciones.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Distribucion',
    component: Distribucion
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: Clientes,
    children: [
      {
        path: ':id/:idSuc?',
        name: 'Cliente',
        component: Cliente
      }
    ]
  },
  {
    path: '/devoluciones/:sucursal?',
    name: 'Devoluciones',
    component: () => import('../views/Devoluciones.vue'),
    children: [
      {
        path: '/devolucion/:id',
        name: 'Devolucion',
        component: () => import('../views/Devolucion.vue')
      }
    ]
  },
  {
    path: '/despachos',
    name: 'Despachos',
    component: () => import('../views/Despachos.vue'),
    children: [
      {
        path: '/despacho/:id',
        name: 'Despacho',
        component: () => import('../views/Despacho.vue')
      }
    ]
  },
  {
    path: '/reportes',
    name: 'Reportes',
    component: () => import('../views/Reportes.vue')
  },
  {
    path: '/reportes/PedidosvsDespacho',
    name: 'PedidosvsDespachos',
    component: () => import('../components/reportes/PedidosVsDespacho.vue')
  },
  {
    path: '/reportes/PendienteVsDespachado',
    name: 'PendienteVsDespachado',
    component: () => import('../components/reportes/PendienteVsDespachado.vue')
  },
  {
    path: '/reportes/PedidoAcumuladoVsDespachado',
    name: 'PedidoAcumuladoVsDespachado',
    component: () => import('../components/reportes/PedidoAcumuladoVsDespachado.vue')
  },
  {
    path: '/reportes/KilosVendidos',
    name: 'KilosVendidos',
    component: () => import('../components/reportes/KilosVendidos.vue')
  },
  {
    path: '/pedidos/:sucursal?',
    name: 'Pedidos',
    component: () => import('../views/Pedidos.vue'),
    children: [
      {
        path: '/pedido/:id',
        name: 'Pedido',
        component: () => import('../views/Pedido.vue')
      }
    ]
  },
  {
    path: '/restricciones-pedido',
    name: 'Restricciones',
    component: Restricciones
  },
  {
    path: '/NotaDeEntrega/:id',
    name: 'NotaDeEntrega',
    component: () => import('../components/distribucion/NotaDeEntrega.vue')
  },
  {
    path: '/OrdenDeSalida/:id',
    name: 'OrdenDeSalida',
    component: () => import('../components/distribucion/OrdenDeSalida.vue')
  },
  {
    path: '/zonas',
    name: 'Zonas',
    component: () => import('../views/Zonas.vue')
  },
  {
    path: '/RAD',
    name: 'RAD',
    component: () => import('../views/RAD.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
