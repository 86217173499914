<template>
  <div class="container">
    <mdb-btn
      flat
      dark-waves
      icon="arrow-left"
      type="button"
      title="Regresar"
      @click="$router.go(-1)"
    >
      Regresar
    </mdb-btn>
    <div
      v-if="tiposCliente && tiposCliente.length"
      class="acordeon secundario px-2"
    >
      <div
        v-for="(tipo, index) in tiposCliente"
        :key="tipo.id_tcl + index"
        :class="['item', {'activo': tipo.expandido}]"
      >
        <div
          class="encabezado mb-2"
          @click="tipo.expandido = !tipo.expandido"
        >
          <header>{{ tipo.nom_tcl }}</header>
          <font-awesome-icon
            icon="chevron-down"
            :class="['icono', {'fa-flip-vertical': tipo.expandido}]"
          />
        </div>
        <div v-if="tipo.expandido">
          <TablaRestriccion
            :clienteId="tipo.id_tcl"
            :condicionesPago="condicionesPago"
            :tiposPrecio="tiposPrecio"
            :tiposDocumento="tiposDocumento"
            :promocionesPago="promocionesPago"
            :nombreCliente="tipo.nom_tcl"
            :restriccionesClientes="restriccionPorTipoCliente(tipo.id_tcl) "
            @agregar-restriccion="agregarRestriccion($event)"
            @eliminar-restriccion="modalConfirmacionEliminar=true; restriccionEliminar=$event"
            @actualizar-estatus="actualizarEstatusRestriccion($event)"
          />
        </div>
      </div>
    </div>
    <div v-if="cargando" class="mensaje-no-items py-3">
      <LoaderKel />
      <p class="texto mt-2">Cargando</p>
    </div>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="cargando"
      :mostrar-modal="modalConfirmacionEliminar"
      titulo="¡Esta acción es irreversible!"
      mensaje="¿Está seguro que desea eliminar la restricción?"
      textoBotonConfirmar="Eliminar"
      @cerrar="modalConfirmacionEliminar = false; restriccionEliminar=null"
      @confirmar="eliminarRestriccion()"
    />
  </div>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import LoaderKel from '@/components/LoaderKel.vue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import { mdbBtn } from 'mdbvue'
import TablaRestriccion from '../components/TablaRestriccion.vue'
export default {
  name: 'Restricciones',
  components: {
    LoaderKel,
    AlertaMensaje,
    ConfirmacionEliminar,
    mdbBtn,
    TablaRestriccion
  },
  data () {
    return {
      tiposCliente: [],
      modalConfirmacionEliminar: false,
      restriccionEliminar: null,
      cargando: false,
      alertaMensaje: {
        contenido: ''
      },
      condicionesPago: [],
      tiposPrecio: [],
      tiposDocumento: [],
      promocionesPago: [],
      restricciones: [
      ]
    }
  },
  mounted () {
    this.obtenerRestricciones()
    this.obtenerCondicionesPago()
  },
  methods: {
    restriccionPorTipoCliente (clienteId) {
      if (this.restricciones.length) {
        return this.restricciones.filter((restriccion) => restriccion.id_tcl_rped === clienteId)
      }
      return []
    },
    mostrarAlerta (contenido, tipo) {
      this.alertaMensaje = {
        contenido,
        tipo
      }
    },
    async obtenerCondicionesPago () {
      this.cargando = true
      try {
        const [data, promocionesPago, tipoDocumento] = await Promise.all([
          apiPost({ s: 'clientes_generalMv' }, ''),
          apiPost({ s: 'promocionesMv' }, ''),
          apiPost({ s: 'tipo_docMv' }, '')
        ])
        this.condicionesPago = data.data.cp
        this.tiposPrecio = data.data.tpre
        this.tiposDocumento = tipoDocumento.data.tdoc
        this.promocionesPago = promocionesPago.data.res
        this.tiposCliente = data.data.tcl.map((tipoCliente) => {
          tipoCliente.expandido = false
          return tipoCliente
        })
      } catch (error) {
        this.mostrarAlerta(
          'Ocurrió un error obteniendo las condiciones de pago',
          'errror'
        )
      } finally {
        this.cargando = false
      }
    },
    async agregarRestriccion (datosRestriccion) {
      this.cargando = true
      try {
        const { data } = await apiPost({ s: 'crearRestriccionMv' }, { id_tcl: datosRestriccion.id_tcl_rped, id_cp: datosRestriccion.id_cp_rped, id_tpre: datosRestriccion.id_tpre_rped, id_tdoc: datosRestriccion.id_tdoc_rped, id_prm: datosRestriccion.id_prm_rped })
        if (data.r) {
          this.mostrarAlerta(data.e, 'correcto')
          this.obtenerRestricciones()
          return
        }
        this.mostrarAlerta(data.e, 'advertencia')
      } catch (error) {
        this.mostrarAlerta(error.message, 'error')
      } finally {
        this.cargando = false
      }
    },
    async eliminarRestriccion () {
      this.cargando = true
      try {
        const { data } = await apiPost({ s: 'eliminarRestriccionMv' }, { id_rped: this.restriccionEliminar.id_rped })
        if (data.r) {
          this.mostrarAlerta(data.e, 'correcto')
          const cantidadRestriccionAntesDeEliminar = JSON.parse(JSON.stringify(this.restricciones)).length
          this.restricciones = this.restricciones.filter((restriccion) => restriccion.id_rped !== this.restriccionEliminar.id_rped)
          // En caso no se elimine ninguna restriccion, vuelva a obtener las restricciones
          if (cantidadRestriccionAntesDeEliminar === this.restricciones.length) {
            this.obtenerRestricciones()
          }
          this.restriccionEliminar = null
          return
        }
        this.mostrarAlerta(data.e, 'advertencia')
      } catch (error) {
        this.mostrarAlerta('Ocurrió un erorr eliminando la restricción', 'error')
      } finally {
        this.cargando = false
        this.modalConfirmacionEliminar = false
      }
    },
    async obtenerRestricciones () {
      this.cargando = true
      try {
        const { data: { res } } = await apiPost({ s: 'restriccionesMv' }, '')
        this.restricciones = res
      } catch (error) {
        this.mostrarAlerta(error.message, 'error')
      } finally {
        this.cargando = false
      }
    },
    async actualizarEstatusRestriccion (restriccion) {
      this.cargando = true
      try {
        const { data } = await apiPost({ s: 'actualizarEstatusRestriccionMv' }, { id_rped: restriccion.id_rped, hab_pre: restriccion.hab_rped })
        if (data.r) {
          this.mostrarAlerta(data.e, 'correcto')
          this.obtenerRestricciones()
          return
        }
        this.mostrarAlerta(data.e, 'advertencia')
      } catch (error) {
        this.mostrarAlerta(error.message, 'error')
      } finally {
        this.cargando = false
      }
    }
  }
}
</script>

<style>
</style>
