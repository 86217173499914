<template>
  <section>
    <div id="app">
      <Menu v-if="!ocultarMenu" />
      <div id="contenido" :class="{ 'ocultar-menu': ocultarMenu }">
        <router-view />
      </div>
    </div>
    <AlertaSnackBar
      :alerta-snack-bar="{
        color: 'informativo',
        contenido: 'Nueva versión disponible',
      }"
      :btn-accion="{ icono: 'sync', texto: 'Clic para actualizar'}"
      :mostrar-snack-bar="notificacionNuevaVersion"
      @btn-accion="actualizarApp"
    />
  </section>
</template>
<script>
import Menu from '@/components/Menu.vue'
import AlertaSnackBar from '@/components/AlertaSnackBar.vue'

export default {
  name: 'App',
  components: { Menu, AlertaSnackBar },
  data () {
    return {
      cargandoApp: false,
      detalles: null,
      estaActualizando: false,
      notificacionNuevaVersion: false
    }
  },
  methods: {
    mostrarActualizacion (e) {
      this.detalles = e.detail
      this.notificacionNuevaVersion = true
    },
    actualizarApp () {
      this.notificacionNuevaVersion = false
      if (!this.detalles || !this.detalles.waiting) return
      this.detalles.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  },
  computed: {
    ocultarMenu () {
      const ruta = this.$route.path.slice(1).split('/')[0]
      return ruta === 'login'
    }
  },
  created () {
    this.cargandoApp = true
    setTimeout(() => (this.cargandoApp = false), 250)
    document.addEventListener('updateSW', this.mostrarActualizacion, {
      once: true
    })
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.estaActualizando) return
        this.estaActualizando = true
        window.location.reload()
      })
    }
  }
}
</script>

<style lang="scss">
$image-path: "~@/../mdb/mdbvue/img";
@import "~@/../mdb/mdbvue/scss/mdb-free.scss";

// Estilos para mostrar / ocultar Menu en mobile
#contenido:not(.ocultar-menu) {
  transition-property: margin-left, width;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  @media screen and (min-width: 992px) {
    margin-left: 205px;
    width: calc(100% - 205px); /* - ancho del navbar */
  }
}

// Estilos para comprimir el Menu en desktop
.comprimido + #contenido:not(.ocultar-menu) {
  @media screen and (min-width: 992px) {
    margin-left: 64px;
    width: calc(100% - 64px); /* - ancho del navbar */
  }
}
</style>
