<template>
  <section>
    <div
      v-if="mostrarFiltros"
      class="container"
    >
      <FiltrosTipo1
        :zonas="data.zon"
        :ven="vendedores"
        :tipos-cli="tiposCli"
        :con-de-pago="conPago"
        :tipPrecio="tipPrecio"
        :restablecerFiltrado="restablecerFiltrado"
        @zonasAFiltrar="cargando = true; aplicarFiltrosZonas($event.estados, $event.municipios, $event.parroquias)"
        @vendedorAFiltrar="cargando = true; vendedoresAFiltrar = $event; aplicarFiltros()"
        @tiposClienteAFiltrar="cargando = true; tiposClienteAFiltrar = $event; aplicarFiltros()"
        @condiPagoAFiltrar="cargando = true; condiPagoAFiltrar = $event; aplicarFiltros()"
        @tipoPrecioAFiltrar="tipoPrecioAFiltrar=$event; aplicarFiltros()"
        @eliminarFiltros="eliminarFiltrosSeleccionados"
      />
    </div>
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <div
      v-else-if="!cargando && directo"
      class="pt-4 px-3"
    >
      <ul class="table resumen">
        <li class="encabezado sticky">
          <div class="row align-items-center pl-2 p-2">
            <header class="col-12 col-sm h4-responsive">Resumen</header>
            <div class="col-4 col-sm-auto text-center">
              <div class="custom-checkbox">
                <input
                  v-model="resumenMode"
                  type="radio"
                  id="ver-resumen-zonas"
                  value="zonas"
                  name="radioMode"
                >
                <label :class="['custom-checkbox-label mb-0', {'checked': resumenMode == 'zonas'}]" for="ver-resumen-zonas">
                  <font-awesome-icon icon="map-marker-alt" />
                  Zonas
                </label>
              </div>
            </div>
            <div class="col-4 col-sm-auto text-center">
              <div class="custom-checkbox">
                <input
                  v-model="resumenMode"
                  type="radio"
                  id="ver-resumen-vendedor"
                  value="vendedor"
                  name="radioMode"
                >
                <label :class="['custom-checkbox-label mb-0', {'checked': resumenMode == 'vendedor'}]" for="ver-resumen-vendedor">
                  <font-awesome-icon icon="user" />
                  Vendedor
                </label>
              </div>
            </div>
            <div class="col-4 col-sm-auto text-center">
              <div class="custom-checkbox">
                <input
                  v-model="resumenMode"
                  type="radio"
                  id="ver-resumen-productos"
                  value="productos"
                  name="radioMode"
                >
                <label :class="['custom-checkbox-label mb-0', {'checked': resumenMode == 'productos'}]" for="ver-resumen-productos">
                  <font-awesome-icon icon="box" />
                  Productos
                </label>
              </div>
            </div>
          </div>
          <p class="descripcion px-2">Breve resumen de pedidos. Selecciona ver por Zonas, Vendedor o Productos.</p>
          <div v-if="resumenMode == 'zonas'" class="thead claro">
            <div title="Filtro por zona" class="col-12 col-sm-3 tooltip-filter">
              Zona
            </div>
            <div title="Detalles del cliente" class="col-12 col-sm col-md-3 col-lg py-0 pl-lg-0 text-center tooltip-modal">
              Nº de clientes
              <div class="row mx-0">
                <span class="col-6 text-right small-block">
                  Empresas
                </span>
                <span class="col-6 text-right small-block">
                  Sucursales
                </span>
              </div>
            </div>
            <div title="Detalles de la zona" class="col-12 col-sm-2 col-md py-0 text-sm-right tooltip-modal">
              Cantidad pedida
              <span class="small-block">Kilos pedidos</span>
            </div>
            <div class="col-12 col-sm-auto col-md-4 columna-despacho text-center">Acciones</div>
          </div>
          <div v-if="resumenMode == 'vendedor'" class="thead claro">
            <div title="Filtro por vendedor" class="col-12 col-sm-3 tooltip-filter">
              Vendedor
            </div>
            <div title="Detalles del cliente" class="col-12 col-sm col-md-3 col-lg py-0 pl-lg-0 text-center tooltip-modal">
              Nº de clientes
              <div class="row mx-0">
                <span class="col-6 text-right small-block">
                  Empresas
                </span>
                <span class="col-6 text-right small-block">
                  Sucursales
                </span>
              </div>
            </div>
            <div title="Detalles de la zona" class="col-12 col-sm-2 col-md py-0 text-sm-right tooltip-modal">
              Cantidad pedida
              <span class="small-block">Kilos pedidos</span>
            </div>
            <div class="col-12 col-sm-auto col-md-4 columna-despacho text-center">Acciones</div>
          </div>
          <div
            v-if="resumenMode == 'productos'"
            class="thead claro pl-4"
          >
            <div
              title="Filtro por vendedor"
              class="col-12 col-sm"
            >
              Presentación
            </div>
            <div
              title="Detalles de la zona"
              class="col-12 col-sm-3 py-0 text-left tooltip-modal"
            >
              Estados
              <span class="small-block">
                Vendedores
              </span>
            </div>
            <div
              class="col-12 col-sm-2 col-md py-0 text-sm-right"
            >
              Cantidad pedida
              <span class="small-block">
                Kilos pedidos
              </span>
            </div>
            <div class="col-12 col-sm-2 col-md-3 columna-despacho text-center">
              Acciones
            </div>
          </div>
        </li>
        <div v-if="resumenMode == 'zonas'">
          <li
            v-for="(totalesZon, i) in totalesPorZonas"
            :key="i"
            class="contenido"
            @click="estadoDetalles = totalesZon; mostrarDetallesZonas = true"
          >
            <div
              class="col-12 col-sm-3 cursor-pointer"
              data-columna="Zona"
              @click.stop="estadosAFiltrar=totalesZon.est_zon; aplicarFiltros()"
            >
              {{totalesZon.est_zon}}
            </div>
            <div
              class="col-12 col-sm col-md-3 col-lg px-sm-1 cursor-pointer"
              data-columna="Nº de clientes"
              @click.stop="mostrarClientes = !mostrarClientes, zon_id = i"
            >
              <div class="row mx-0 px-sm-3 w-100">
                <div class="col-auto col-sm px-1 text-sm-center">
                  {{Object.keys(totalesZon.clientes).length}}
                  <span class="d-sm-none">Empresas</span>
                </div>
                <div class="col-auto col-sm px-1 text-sm-center">
                  {{sucursalesZonas[totalesZon.est_zon]}}
                  <span class="d-sm-none">Sucursales</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-2 col-md celda-numerica cursor-pointer" data-columna="Cantidad pedida">
              <p>
                {{totalesZon.cantidad}}
                <span class="small-block d-none d-sm-block">{{Number(totalesZon.peso.toFixed(2))}} KG</span>
              </p>
            </div>
            <div class="col-12 celda-numerica cursor-pointer d-sm-none" data-columna="Kilos pedidos">
              <p>{{Number(totalesZon.peso.toFixed(2))}}</p>
            </div>
            <div class="col-12 col-sm-auto col-md-4 px-sm-2 columna-despacho" data-columna="Despachable" @click.stop>
              <div class="text-sm-center d-md-flex align-items-center">
                <div class="col-12 col-md-7 col-lg p-0 m-0">
                  <mdb-btn
                    v-if="totalesZon.despachable"
                    flat
                    dark-waves
                    icon="check"
                    class="m-0 m-sm-1 m-md-0 p-1 btn-bordeado"
                    @click="despacharResumen(totalesZon.est_zon)"
                  >
                    Despachar
                  </mdb-btn>
                </div>
                <mdb-btn
                  flat
                  dark-waves
                  icon="clipboard-check"
                  class="m-0 m-sm-1 m-md-0 p-1"
                  title="Pasar a estatus Completo los pedidos de este estado que se encuentren en estatus Incompleto"
                  @click="pedidosCom = totalesZon.est_zon; modalConfirmacionEliminar = true"
                >
                  <small>Pasar a completo</small>
                </mdb-btn>
              </div>
            </div>
          </li>
        </div>
        <div v-if="resumenMode == 'vendedor'">
          <li
            v-for="(totalesVen, i) in totalesPorVendedor"
            :key="i"
            class="contenido"
            @click="estadoDetalles = totalesVen; mostrarDetallesZonas = true"
          >
            <div
              class="col-12 col-sm-3 cursor-pointer"
              data-columna="Vendedor"
              @click.stop="vendedoresAFiltrar.push(totalesVen.id_per); aplicarFiltros()"
            >
              {{totalesVen.nom_per}} {{totalesVen.ape_per}}
            </div>
            <div
              class="col-12 col-sm col-md-3 col-lg px-sm-1 cursor-pointer"
              data-columna="Nº de clientes"
              @click.stop="mostrarClientes = !mostrarClientes, ven_id = i"
            >
              <div class="row mx-0 px-sm-3 w-100">
                <div class="col-auto col-sm px-1 text-sm-center">
                  {{Object.keys(totalesVen.clientes).length}}
                  <span class="d-sm-none">Empresas</span>
                </div>
                <div class="col-auto col-sm px-1 text-sm-center">
                  {{sucursalesVendedor[totalesVen.id_per]}}
                  <span class="d-sm-none">Sucursales</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-2 col-md celda-numerica cursor-pointer" data-columna="Cantidad pedida">
              <p>
                {{totalesVen.cantidad}}
                <span class="small-block d-none d-sm-block">{{Number(totalesVen.peso.toFixed(2))}} KG</span>
              </p>
            </div>
            <div class="col-12 celda-numerica cursor-pointer d-sm-none" data-columna="Kilos pedidos">
              <p>{{Number(totalesVen.peso.toFixed(2))}}</p>
            </div>
            <div class="col-12 col-sm-auto col-md-4 px-sm-2 columna-despacho" data-columna="Despachable" @click.stop>
              <div class="text-sm-center d-md-flex align-items-center">
                <div class="col-12 col-md-7 col-lg p-0 m-0">
                  <mdb-btn
                    v-if="totalesVen.despachable"
                    flat
                    dark-waves
                    icon="check"
                    class="m-0 m-sm-1 m-md-0 p-1 btn-bordeado"
                    @click="despacharResumen(totalesVen.id_per)"
                  >
                    Despachar
                  </mdb-btn>
                </div>
                <mdb-btn
                  flat
                  dark-waves
                  icon="clipboard-check"
                  class="m-0 m-sm-1 m-md-0 p-1"
                  title="Pasar a estatus Completo los pedidos de este vendedor que se encuentren en estatus Incompleto"
                  @click="pedidosCom = totalesVen.id_per; modalConfirmacionEliminar = true"
                >
                  <small>Pasar a completo</small>
                </mdb-btn>
              </div>
            </div>
          </li>
        </div>
        <div v-if="resumenMode == 'productos'">
          <li
            v-for="cat in totalesPorProducto"
            :key="'t'+cat.id_cat"
            class="row-nowrap align-items-center contenido-categoria"
          >
            <div>
              <div
                class="column-center bold p-0 cursor-pointer"
                @click="estadoDetalles = cat; mostrarDetallesZonas = true"
              >
                <div class="rotar-titulo">
                  {{cat.nom_cat}}
                </div>
              </div>
            </div>
            <div class="w-100 p-0">
              <li
                class="contenido"
                v-for="(producto, p) in cat.notas"
                :key="p"
              >
                <div
                  class="col-12 col-sm"
                  data-columna="Presentación"
                >
                  {{ producto.producto }} - {{ producto.presentacion }}
                </div>
                <div
                  class="col-12 col-sm-3 cursor-pointer"
                  data-columna="Estados"
                  @click.stop="mostrarClientes = !mostrarClientes, producto_id = {cat: cat.id_cat, pro: p}"
                >
                  <span
                    class="auto-coma"
                    v-for="(estados, e) in producto.estados"
                    :key="e"
                  >
                    {{ estados.est_zon }}
                  </span>
                  <span class="d-none d-sm-block small-block">
                    <span
                      class="auto-coma"
                      v-for="(vendedores, v) in producto.vendedores"
                      :key="v"
                    >
                      {{ vendedores.nom_per }} {{ vendedores.ape_per }}
                    </span>
                  </span>
                </div>
                <div
                  class="col-12 d-sm-none cursor-pointer"
                  data-columna="Vendedores"
                  @click.stop="mostrarClientes = !mostrarClientes, producto_id = {cat: cat.id_cat, pro: p}"
                >
                  <span
                    class="auto-coma"
                    v-for="(vendedores, v) in producto.vendedores"
                    :key="v"
                  >
                    {{ vendedores.nom_per }} {{ vendedores.ape_per }}
                  </span>
                </div>
                <div
                  class="col-12 col-sm-2 col-md celda-numerica"
                  data-columna="Cantidad pedida"
                >
                  <p>
                    {{producto.cantidad}}
                    <span class="small-block d-none d-sm-block">{{Number(producto.peso.toFixed(2))}} KG</span>
                  </p>
                </div>
                <div class="col-12 celda-numerica d-sm-none" data-columna="Kilos pedidos">
                  <p>{{Number(producto.peso.toFixed(2))}}</p>
                </div>
                <div
                  class="col-12 col-sm-2 col-md-3 px-sm-2"
                  data-columna="Acciones"
                  @click.stop
                >
                  <div class="text-sm-center">
                    <mdb-btn
                      v-if="producto.despachable"
                      flat
                      dark-waves
                      icon="check"
                      class="m-0 m-sm-1 m-md-0 p-1 btn-bordeado"
                      @click="despacharResumen({estados: producto.estados, vendedores: producto.vendedores})"
                    >
                      Despachar
                    </mdb-btn>
                  </div>
                </div>
              </li>
            </div>
          </li>
        </div>
        <li class="contenido claro d-md-flex">
          <div :class="['col-12 pl-sm-2 d-none d-sm-block text-uppercase font-weight-bold', resumenMode == 'productos' ? 'col-sm' : 'col-sm-3']">
            Totales
          </div>
          <div
            v-if="resumenMode !== 'productos'"
            class="col-12 col-sm col-md-3 col-lg px-sm-1"
            data-columna="Total nº de clientes"
          >
            <div class="row mx-0 px-sm-3 w-100">
              <div class="col-auto col-sm px-1 text-sm-center">
                {{ tablaReTotal.empTotal ? Object.keys(tablaReTotal.empTotal).length : 0}}
                <span class="d-sm-none">Empresas</span>
              </div>
              <div class="col-auto col-sm px-1 text-sm-center">
                {{ tablaReTotal.sucTotal ? Object.keys(tablaReTotal.sucTotal).length : 0}}
                <span class="d-sm-none">Sucursales</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-2 col-md text-sm-right" data-columna="Total cantidad pedida">
            {{tablaReTotal.canPedida}}
            <span class="small-block d-none d-sm-block">{{Number(tablaReTotal.kiPedidos).toFixed(2)}} KG</span>
          </div>
          <div :class="['col-auto px-md-2 columna-despacho', resumenMode == 'productos' ? 'col-sm-2 col-md-3' : 'col-md-4']"></div>
        </li>
      </ul>
    </div>
    <div
      v-else
      class="container"
    >
      <div :class="['row align-items-center', {'justify-content-between': Object.keys(zonasPed).length}]">
        <div class="col-auto px-sm-0 px-lg-3">
          <mdb-btn
            flat
            dark-waves
            icon="arrow-left"
            class="m-0 px-3"
            @click="volverAlResumen"
          >
            Regresar
          </mdb-btn>
        </div>
        <div
          v-if="vendedorSeleccionado.length === 1"
          class="col-12 col-sm"
        >
          <span class="small-block text-muted">VENDEDOR</span>
          {{ vendedorSeleccionado[0].nom_per }} {{ vendedorSeleccionado[0].ape_per }}
        </div>
        <div
          v-if="vendedorSeleccionado.length === 1 && Object.keys(zonasPed).length"
          class="w-100 d-md-none"
        />
        <div
          v-if="Object.keys(zonasPed).length"
          :class="[vendedorSeleccionado.length === 1 ? 'col' : 'col-auto', 'py-2 pl-lg-0']"
        >
          <div class="custom-control custom-switch secundario">
            <input
              v-model="seleccionarTodosP"
              id="customSwitches-seleccionar-pedidos"
              type="checkbox"
              class="custom-control-input secundario"
              title="Seleccionar todos los pedidos"
              @click="cargando = true; seleccionarTodos($event.target.checked)"
            >
              <label
                class="custom-control-label"
                for="customSwitches-seleccionar-pedidos"
              >
                Seleccionar todos los pedidos
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-auto mb-sm-2 mb-md-0 py-2 px-0 px-sm-2 text-center btn-checkgroup-contenedor no-print">
        <span
          v-for="cat in categorias"
          :key="'cat-'+cat.id_cat"
        >
          <input
            v-model="categoriasAMostrar"
            :id="`cat-${cat.id_cat}`"
            type="checkbox"
            name="checkbox-categoria"
            :value="cat.id_cat"
          >
          <label :for="`cat-${cat.id_cat}`" class="btn-checkgroup">
            {{cat.nom_cat}}
          </label>
        </span>
      </div>
      <!-- Mensaje cuando no se encuentran pedidos -->
      <div
        v-if="!cargando && !Object.keys(zonasPed).length"
        class="mensaje-no-items py-3"
      >
        <font-awesome-icon
          icon="calendar-times"
          size="5x"
          class="icono"
        />
        <p class="texto">No hay pedidos por despachar</p>
      </div>
    </div>
    <template v-if="Object.keys(zonasPed).length">
      <div
        v-for="z in zonasPed"
        :key="`pedidoID-${z.id_zon}`"
        class="mt-2 mb-4 px-3"
      >
        <TablaDePedidos
          class="maxH-80"
          mostrar-encabezado
          :cate-mostrar="categoriasAMostrar"
          :categorias="Object.keys(categorias).length ? categorias : null "
          :datos-pedidos="Object.keys(z.pedidos).length ? z.pedidos : null "
          :identificador="z.id_zon"
          :titulo="`${z.ciu_zon}, ${z.sec_zon}`"
          :totales-por-presentacion="totalesPorPresentacion[z.id_zon] ? totalesPorPresentacion[z.id_zon] : totalesPorPresentacion"
          :totales-por-presentacion-general="totalesPorPresentacion"
          :totales-por-pedido="totalesPorPedido[z.id_zon] ? totalesPorPedido[z.id_zon] : totalesPorPedido"
          :totales-por-pedidos-pedido="totalesPorPedidosPedido[z.id_zon]"
          :totales-por-presentacion-pedidos="totalesPorPresentacionPedidos[z.id_zon]"
          :cargaPedido="Object.keys(cargaPedido).length ? cargaPedido[z.id_zon] : null"
          :nuevos="nuevos"
          :moroso="moroso"
          @todos="seleccionarTodos($event.checked, $event.pedido, $event.zona)"
          @actualizar="calcularTotales"
          @cargar="cargarAlCamion($event)"
          ocultar-botones-acciones
        />
      </div>
    </template>
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarDetallesZonas"
      @close="mostrarDetallesZonas = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>Detalles</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="p-0">
        <ul
          v-if="totalesPorZonas && Object.keys(totalesPorZonas).length"
          class="table detalles m-0"
        >
          <li class="encabezado sticky">
            <div class="thead claro">
              <div class="col-12 col-sm-5">
                Producto
                <span class="small-block">Descripción - Categoría</span>
              </div>
              <div class="col-12 col-sm pl-1">
                Peso existente
                <span class="small-block">Cantidad existente</span>
              </div>
              <div class="col-12 col-sm pl-1">
                Peso pedido
                <span class="small-block">Cantidad pedida</span>
              </div>
              <div class="col-12 col-sm-2 pl-1">
                Saldo
              </div>
            </div>
          </li>
          <template v-if="estadoDetalles && estadoDetalles.notas && Object.keys(estadoDetalles.notas).length">
            <li
              v-for="(esDeNo, i) in estadoDetalles.notas"
              :key="i"
              :class="[{'fila-error' : esDeNo.existenciaCantidad < esDeNo.cantidad}, 'contenido hover-claro']"
            >
              <div class="col-12 col-sm-5 pr-1" data-columna="Producto / Descripción - Categoría">
                <p>
                  <span> {{ esDeNo.producto }} </span>
                  <span class="small-block text-uppercase">
                    {{ presentacionesTodas[i].des_pre }} -
                    {{ esDeNo.categoria }}
                  </span>
                </p>
              </div>
              <div class="col-12 col-sm pl-sm-1 celda-numerica text-break" data-columna="Peso existente / Cantidad existente">
                <p :class="{'texto-error' : esDeNo.existenciaCantidad < esDeNo.cantidad}">
                  {{Number(esDeNo.existenciaPeso).toFixed(2)}}
                  <span class="small-block">{{esDeNo.existenciaCantidad}}</span>
                </p>
              </div>
              <div class="col-12 col-sm pl-sm-1 celda-numerica text-break" data-columna="Peso pedido / Cantidad pedida">
                <p :class="{'texto-error' : esDeNo.existenciaCantidad < esDeNo.cantidad}">
                  {{Number(esDeNo.peso).toFixed(2)}}
                  <span class="small-block">{{esDeNo.cantidad}}</span>
                </p>
              </div>
              <div class="col-12 col-sm-2 pl-sm-1 celda-numerica text-break" data-columna="Saldo">
                <p :class="{'texto-error' : esDeNo.existenciaCantidad < esDeNo.cantidad}">
                  {{Number(esDeNo.saldo_peso).toFixed(2)}}
                  <span class="small-block">{{esDeNo.saldo}}</span>
                </p>
              </div>
            </li>
            <li class="contenido claro">
              <div class="col-5 font-weight-bold text-uppercase d-none d-sm-block">
                Total peso pedido
              </div>
              <div class="col celda-numerica text-break" data-columna="Total peso pedido">
                <p class="font-weight-bold">{{Number(totalPesoPedido).toFixed(1)}}</p>
              </div>
              <div class="col-2 d-none d-sm-block"></div>
            </li>
          </template>
          <li v-else class="mensaje-no-items py-3">
            <font-awesome-icon
              icon="clipboard"
              size="5x"
              class="icono"
            />
            <p class="texto">No ha realizado pedidos</p>
          </li>
        </ul>
        <div v-else class="mensaje-no-items pb-3">
          <font-awesome-icon
            icon="boxes"
            size="5x"
            class="icono"
          />
          <p class="texto">No ha realizado pedidos</p>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarClientes"
      @close="mostrarClientes = false, zon_id = '', ven_id = '', producto_id = {}"
    >
      <mdb-modal-body class="p-0">
        <ul class="table clientes mb-0" v-if="typeof zon_id !== 'string' && resumenMode == 'zonas'">
          <li class="encabezado sticky">
            <div class="d-flex align-items-center">
              <div class="col">
                <header class="h4-responsive text-capitalize">
                  {{totalesPorZonas[zon_id].est_zon}}
                </header>
                <p class="descripcion">
                  En lista
                  {{ Object.keys(totalesPorZonas[zon_id].clientes).length }} Empresas y
                  {{ sucursalesZonas[totalesPorZonas[zon_id].est_zon] }} Sucursales.
                </p>
              </div>
              <a class="col-auto close mr-3" @click="mostrarClientes = false">×</a>
            </div>
            <div class="thead claro">
              <div title="Notas de pedido" class="col-12 col-sm tooltip-modal-custom">Cliente - Sucursal</div>
              <div class="col-12 col-sm-3 px-0">Condición de pago</div>
              <div class="col-12 col-sm-3 pr-1">Tipo de precio</div>
            </div>
          </li>
          <template v-for="(cliente, index) in totalesPorZonas[zon_id].clientes">
            <li
              v-for="(suc, i) in totalesPorZonas[zon_id].clientes[index].suc"
              :key="i+index"
              @click="notasPedidoModal(suc)"
              class="contenido"
            >
              <div class="col cursor-pointer" data-columna="Cliente - Sucursal">
                <p>{{cliente.nom_emp}} - {{suc.nom_suc}}</p>
              </div>
              <div class="col-12 col-sm-3 px-md-0" data-columna="Condición de pago">
                <p>{{suc.cp}}</p>
              </div>
              <div class="col-12 col-sm-3 pr-md-1" data-columna="Tipo de precio">
                <p>{{suc.tpre}}</p>
              </div>
            </li>
          </template>
        </ul>
        <ul class="table clientes mb-0" v-if="typeof ven_id !== 'string' && resumenMode == 'vendedor'">
          <li class="encabezado sticky">
            <div class="d-flex align-items-center">
              <div class="col">
                <header class="h4-responsive text-capitalize">
                  {{totalesPorVendedor[ven_id].nom_per}} {{totalesPorVendedor[ven_id].ape_per}}
                </header>
                <p class="descripcion">
                  En lista
                  {{ Object.keys(totalesPorVendedor[ven_id].clientes).length }} Empresas y
                  {{ sucursalesVendedor[totalesPorVendedor[ven_id].id_per] }} Sucursales.
                </p>
              </div>
              <a class="col-auto close mr-3" @click="mostrarClientes = false">×</a>
            </div>
            <div class="thead claro">
              <div title="Notas de pedido" class="col-12 col-sm tooltip-modal-custom">Cliente - Sucursal</div>
              <div class="col-12 col-sm-3 px-0">Condición de pago</div>
              <div class="col-12 col-sm-3 pr-1">Tipo de precio</div>
            </div>
          </li>
          <template v-for="(cliente, index) in totalesPorVendedor[ven_id].clientes">
            <li
              v-for="(suc, i) in totalesPorVendedor[ven_id].clientes[index].suc"
              :key="i+index"
              @click="notasPedidoModal(suc)"
              class="contenido"
            >
              <div class="col cursor-pointer" data-columna="Cliente - Sucursal">
                <p>{{cliente.nom_emp}} - {{suc.nom_suc}}</p>
              </div>
              <div class="col-12 col-sm-3 px-md-0" data-columna="Condición de pago">
                <p>{{suc.cp}}</p>
              </div>
              <div class="col-12 col-sm-3 pr-md-1" data-columna="Tipo de precio">
                <p>{{suc.tpre}}</p>
              </div>
            </li>
          </template>
        </ul>
        <ul class="table clientes mb-0" v-if="Object.keys(producto_id).length && resumenMode == 'productos'">
          <div class="d-flex align-items-center py-2">
            <div class="col">
              <header class="h4-responsive">
                Detalles del cliente
              </header>
            </div>
            <a class="col-auto close mr-3" @click="mostrarClientes = false">×</a>
          </div>
          <div v-for="(zonas, z) in totalesPorProducto[producto_id.cat].notas[producto_id.pro].clientes" :key="z">
            <li class="encabezado sticky">
              <div v-for="(vendedor, v) in zonas" :key="v">
                <p class="text-capitalize px-3">
                  {{ z }}
                  <span class="small-block">
                    {{ vendedores.filter((vendedor) => vendedor.id_per === v)[0].nom_per }} {{ vendedores.filter((vendedor) => vendedor.id_per === v)[0].ape_per }}
                  </span>
                </p>
                <div class="thead claro">
                  <div title="Notas de pedido" class="col-12 col-sm tooltip-modal-custom">Cliente - Sucursal</div>
                  <div class="col-12 col-sm-3 px-0">Condición de pago</div>
                  <div class="col-12 col-sm-3 pr-1">Tipo de precio</div>
                </div>
              </div>
            </li>
            <div v-for="(vendedor, v) in zonas" :key="`${z}${v}`">
              <div v-for="(producto, p) in vendedor" :key="`${p}${v}`">
                <li
                  v-for="(suc, i) in producto.suc"
                  :key="`${i}${v}`"
                  @click="notasPedidoModal(suc)"
                  class="contenido"
                >
                  <div class="col cursor-pointer" data-columna="Cliente - Sucursal">
                    <p>{{producto.nom_emp}} - {{suc.nom_suc}}</p>
                  </div>
                  <div class="col-12 col-sm-3 px-md-0" data-columna="Condición de pago">
                    <p>{{suc.cp}}</p>
                  </div>
                  <div class="col-12 col-sm-3 pr-md-1" data-columna="Tipo de precio">
                    <p>{{suc.tpre}}</p>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </ul>
      </mdb-modal-body>
    </mdb-modal>
    <mdb-modal
      centered
      elegant
      size="lg"
      :show="mostrarNotasPedido"
      @close="mostrarNotasPedido = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Notas de pedido de <span v-if="notasPedido.suc">{{ notasPedido.suc.nom_suc }}</span>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="p-0">
        <ul
          v-if="notasPedido.notas && Object.keys(notasPedido.notas).length"
          class="table p-notas m-0"
        >
          <li class="encabezado sticky">
            <div class="d-flex flex-wrap descripcion">
              <small class="col-12 col-sm-6 col-md-3 py-2" v-if="notasPedido.suc">
                <span class="small-block">Condición de pago</span>
                {{ notasPedido.suc.cp }}
              </small>
              <small class="col-12 col-sm-6 col-md-3 py-2" v-if="notasPedido.suc">
                <span class="small-block">Tipo de precio</span>
                {{ notasPedido.suc.tpre }}
              </small>
              <small class="col-12 col-sm-6 col-md-3 py-2">
                <span class="small-block">Presentaciones</span>
                {{ notasPedido.notas && Object.keys(notasPedido.notas).length }}
              </small>
              <small class="col-12">
                <span class="small-block">Observación</span>
                {{ notasPedido.obs || 'Sin observaciones' }}
              </small>
            </div>
            <div class="thead claro">
              <div class="col-12 col-sm-3 col-lg px-2">
                Nombre
                <span class="small-block">Categoría</span>
              </div>
              <div class="col-12 col-sm px-2">
                Descripción
                <span class="small-block">Empaque - Código</span>
              </div>
              <div class="col-12 col-sm-2 px-2 text-sm-right">
                Cantidad - Despachado
                <span class="small-block">Unidad - Peso</span>
              </div>
              <!-- <div class="col-12 col-sm-2 px-2 text-sm-right">
                Cantidad Despachada
              </div> -->
              <div class="col-12 col-sm-2 px-2 text-right">
                Costo
                <span class="small-block">IVA</span>
              </div>
              <div class="col-12 col-sm-2 col-lg-2 px-2 text-right">
                Total
                <span class="small-block">IVA</span>
              </div>
            </div>
          </li>
          <li
            class="contenido"
            v-for="notas in notasPedido.notas"
            :key="notas.id_np"
          >
            <div class="col-12 col-md-3 col-lg px-2" data-columna="Nombre / Categoría">
              <p>
                {{ notas.nombre }}
                <span class="small-block">
                  {{ notas.categoria }}
                </span>
              </p>
            </div>
            <div class="col-12 col-md px-2" data-columna="Descripción / Empaque - Código">
              <p>
                {{ notas.descripcion }}
                <span class="small-block">
                  {{ notas.empaque }} - {{ notas.codigo }}
                </span>
              </p>
            </div>
            <div class="col-12 col-md-2 px-2 text-md-right" data-columna="Cantidad - Despachado / Unidad - Peso">
              <p>
                  {{ notas.cantidad}} - {{ cantidadDes(notas.cantidad, notas.despachado) }}
                  <span class="small-block">
                    {{ notas.unidad }} - {{ notas.peso}}
                  </span>
              </p>
            </div>
            <!-- <div class="col-12 col-md-2 px-2 text-md-right" data-columna="Cantidad despachada">
              <p>
                  {{ cantidadDes(notas.cantidad, notas.despachado) }}
              </p>
            </div> -->
            <div class="col-12 col-md-2 px-2 text-md-right" data-columna="Costo / IVA">
              <p>
                {{notas.costo ? notas.costo : '0.00'}}
                <span class="small-block">
                  {{ notas.iva ? notas.iva : '0.00' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-md-2 px-2 text-md-right" data-columna="Total / IVA">
              <p>
                {{ calculaTotal(notas.cantidad, notas.costo) }}
                <span class="small-block">
                  {{ calcularIva(notas.cantidad, notas.costo, notas.iva) }}
                </span>
              </p>
            </div>
          </li>
          <li
            v-if="Object.keys(notasPedido).length"
            class="contenido claro justify-content-md-end text-md-right"
          >
            <div class="col-12 d-md-none" data-columna="Totales"></div>
            <div class="col-12 col-md-auto" data-columna="Descuento">
              <span class="d-none d-md-block small-block font-weight-bold">Descuento</span>
              <p>{{calcularDescuento()}}</p>
            </div>
            <div class="col-12 col-md-auto" data-columna="Peso">
              <span class="d-none d-md-block small-block font-weight-bold">Peso</span>
              <p>{{calcularTotalPeso()}}</p>
            </div>
            <div class="col-12 col-md-auto" data-columna="IVA">
              <span class="d-none d-md-block small-block font-weight-bold">IVA</span>
              <p>{{calcularTotalIva()}}</p>
            </div>
            <div class="col-12 col-md-auto" data-columna="Subtotal">
              <span class="d-none d-md-block small-block font-weight-bold">Subtotal</span>
              <p>{{calcularSubTotal()}}</p>
            </div>
            <div class="col-12 col-md-auto" data-columna="Total">
                <span class="d-none d-md-block small-block font-weight-bold">Total</span>
                <p>{{calcularMontoTotal()}}</p>
            </div>
          </li>
        </ul>
        <div
          v-else
          class="mensaje-no-items pb-3"
        >
          <font-awesome-icon icon="clipboard-list" size="5x" class="icono" />
          <p class="texto">No hay notas de pedidos</p>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <ConfirmacionEliminar
      :texto-boton-confirmar="'Confirmar'"
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      :mensaje="'Esta opción es irreversible'"
      @cerrar="modalConfirmacionEliminar = false; pedidosCom = ''"
      @confirmar="resumenMode === 'zonas' ? completoZona() : completoVendedor()"
    />
    <CargarCamion
      :mostrar-modal="modalCargarCamion"
      :transportistas="listaTransporte.transportistas"
      :vehiculos="listaTransporte.vehiculos"
      :carga="camion"
      :totales-por-presentacion="camionTotal"
      :presentaciones="presentaciones && Object.keys(presentaciones).length ? presentaciones : null"
      :despachos="data.des"
      :zonas="zonas"
      @cerrar="modalCargarCamion = $event"
      @alerta-mensaje="mostrarAlerta($event)"
      @actualizar="$emit('actualizar'); volverAlResumen();"
      @boton-deshabilitado="$emit('boton-desabilitado', $event)"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
      :tiempoAutoOcultar="5000"
    />
  </section>
</template>

<script>
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbBtn } from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import CargarCamion from '@/components/distribucion/CargarCamion.vue'
import { apiPost } from '@/funciones/api.js'
import TablaDePedidos from '@/components/TablaDePedidos.vue'
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'Zonas',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn,
    AlertaMensaje,
    ConfirmacionEliminar,
    CargarCamion,
    TablaDePedidos,
    FiltrosTipo1,
    LoaderKel
  },
  props: {
    mostrarFiltros: {
      type: Boolean,
      default: false
    },
    botonCargar: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default () {
        return {
          des: []
        }
      }
    }
  },
  data () {
    return {
      mostrarNotasPedido: false,
      notasPedido: {},
      mostrarClientes: false,
      resumenMode: 'zonas',
      zon_id: '',
      ven_id: '',
      producto_id: {},
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      mostrarDetallesZonas: false, // Modal
      zonasVendedor: {},
      pedidosCom: '',
      restablecerFiltrado: false,
      zonasPed: {},
      directo: true, // Zona seleccionada default
      listaTransporte: {
        transportistas: [],
        vehiculos: []
      },
      categoriasAMostrar: ['1'],
      cargasPorZonas: {},
      modalCargarCamion: false,
      totalesPorPresentacion: {},
      totalesPorPedido: {},
      zona: 0,
      estadoDetalles: {},
      filtrosUsados: 0,
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      vendedoresAFiltrar: [],
      tiposClienteAFiltrar: [],
      condiPagoAFiltrar: [],
      tipoPrecioAFiltrar: [],
      vendedorSeleccionado: [],
      seleccionarTodosP: false,
      cargando: false,
      camion: {},
      camionTotal: {},
      sucursalesZonas: {},
      sucursalesVendedor: {}
    }
  },
  computed: {
    zonas () {
      if (!this.data || !this.data.zon) return {}
      const zonas = {}
      this.data.zon.forEach(z => {
        if (!zonas[z.id_zon]) {
          z.pedidos = {}
          zonas[z.id_zon] = z
        }
      })
      return zonas
    },
    vendedores () {
      if (!this.data || !this.data.ven) return []
      return this.data.ven.map((v) => {
        return {
          id_per: v.id_per,
          id_ven: v.id_ven,
          cod_ven: v.cod_ven,
          nom_per: v.nom_per,
          ape_per: v.ape_per
        }
      })
    },
    conPago () {
      if (!this.data || !this.data.cp) return []
      return this.data.cp.map((cp) => {
        return {
          id_cp: cp.id_cp,
          nom_cp: cp.nom_cp
        }
      })
    },
    tipPrecio () {
      if (!this.data || !this.data.tpre) return []
      return this.data.tpre.map((tpre) => {
        return {
          id_tpre: tpre.id_tpre,
          nom_tpre: tpre.nom_tpre
        }
      })
    },
    tiposCli () {
      if (!this.data || !this.data.tcl) return []
      return this.data.tcl.map((tcl) => {
        return {
          id_tcl: tcl.id_tcl,
          nom_tcl: tcl.nom_tcl
        }
      })
    },
    categorias () {
      if (!this.data || !this.data.cat) return {}
      const categorias = {}
      const productos = {}
      this.data.cat.forEach((c) => {
        categorias[c.id_cat] = c
        categorias[c.id_cat].productos = {}
      })
      this.data.pro.forEach((p) => {
        productos[p.id_pro] = p
        productos[p.id_pro].presentaciones = {}
      })
      this.data.pre.forEach((p) => {
        if (Number(p.can_pre) > 0) {
          productos[p.id_pro_pre].presentaciones[p.id_pre] = p
        }
      })
      for (const p in productos) {
        if (Object.keys(productos[p].presentaciones).length) {
          categorias[productos[p].id_cat_pro].productos[productos[p].id_pro] = productos[p]
        }
      }
      for (const c in categorias) {
        if (!Object.keys(categorias[c].productos).length) {
          delete categorias[c]
        }
      }
      return categorias
    },
    categoriasTodas () {
      if (!this.data || !this.data.cat) return {}
      const categorias = {}
      const productos = {}
      this.data.cat.forEach((c) => {
        categorias[c.id_cat] = c
        categorias[c.id_cat].productos = {}
      })
      this.data.pro.forEach((p) => {
        productos[p.id_pro] = p
        productos[p.id_pro].presentaciones = {}
      })
      this.data.pre.forEach((p) => {
        productos[p.id_pro_pre].presentaciones[p.id_pre] = p
      })
      for (const p in productos) {
        if (Object.keys(productos[p].presentaciones).length) {
          categorias[productos[p].id_cat_pro].productos[productos[p].id_pro] = productos[p]
        }
      }
      for (const c in categorias) {
        if (!Object.keys(categorias[c].productos).length) {
          delete categorias[c]
        }
      }
      return categorias
    },
    presentaciones () {
      if (!this.data || !this.data.pre) return {}
      const presentaciones = {}
      this.data.pre.forEach((p) => {
        if (Number(p.can_pre) > 0) {
          presentaciones[p.id_pre] = p
        }
      })
      return presentaciones
    },
    presentacionesTodas () {
      if (!this.data || !this.data.pre) return {}
      const presentaciones = {}
      this.data.pre.forEach((p) => {
        presentaciones[p.id_pre] = p
      })
      return presentaciones
    },
    notasPorPedido () {
      if (!this.data || !this.data.nop) return {}
      const notasPorPedido = {}
      this.data.nop.forEach((np) => {
        if (!notasPorPedido[np.id_ped_np]) {
          notasPorPedido[np.id_ped_np] = {}
        }
        if (!notasPorPedido[np.id_ped_np][np.id_pre_np]) {
          notasPorPedido[np.id_ped_np][np.id_pre_np] = {}
        }
        notasPorPedido[np.id_ped_np][np.id_pre_np].cantidad = np.can_np
        notasPorPedido[np.id_ped_np][np.id_pre_np].inventario = np.can_ni ? np.can_ni : 0
      })
      return notasPorPedido
    },
    totalesPorPresentacionPedidos () {
      const totales = {}
      for (const z in this.zonasPed) {
        for (const ped in this.zonasPed[z].pedidos) {
          for (const pre in this.zonasPed[z].pedidos[ped].notas) {
            if (this.presentaciones[pre] && this.notasPorPedido[ped] && this.notasPorPedido[ped][pre] && this.notasPorPedido[ped][pre]?.cantidad && this.categoriasAMostrar.includes(this.presentaciones[pre].id_cat_pro)) {
              if (!totales[z]) {
                totales[z] = {
                  peso: 0,
                  cantidad: 0
                }
              }
              if (!totales[z][pre]) {
                totales[z][pre] = {
                  peso: 0,
                  cantidad: 0
                }
              }
              const cantidad = Number(this.notasPorPedido[ped][pre].cantidad)
              const peso = Number((Number(this.presentaciones[pre].pesm_pre) * cantidad).toFixed(2))

              totales[z][pre].cantidad += cantidad
              totales[z][pre].peso += peso

              totales[z].peso += peso
              totales[z].cantidad += cantidad
            }
          }
        }
      }
      return totales
    },
    totalesPorPedidosPedido () {
      const totales = {}
      for (const z in this.zonasPed) {
        for (const ped in this.zonasPed[z].pedidos) {
          if (!totales[z]) {
            totales[z] = {}
          }
          if (!totales[z][ped]) {
            totales[z][ped] = {
              peso: 0,
              cantidad: 0
            }
          }
          for (const pre in this.zonasPed[z].pedidos[ped].notas) {
            if (this.presentaciones[pre] && this.notasPorPedido[ped] && this.notasPorPedido[ped][pre] && this.notasPorPedido[ped][pre]?.cantidad && this.categoriasAMostrar.includes(this.presentaciones[pre].id_cat_pro)) {
              const cantidad = Number(this.notasPorPedido[ped][pre].cantidad)
              const peso = Number((Number(this.presentaciones[pre].pesm_pre) * cantidad).toFixed(2))

              totales[z][ped].cantidad += cantidad
              totales[z][ped].peso += peso
            }
          }
        }
      }
      return totales
    },
    tablaReTotal () {
      const totalesZon = {}
      const zonas = []
      if (!this.data || !this.data.zon) return {}
      const totalTabla = {
        kiPedidos: 0,
        canPedida: 0,
        sucTotal: {},
        empTotal: {}
      }
      this.data.zon.forEach((z) => {
        if (z.eli_zon === '1') {
          zonas[z.id_zon] = z
        }
      })
      this.data.ped.forEach((p) => {
        if (this.notasPorPedido[p.id_ped]) {
          if (!totalTabla.empTotal[p.id_emp]) {
            totalTabla.empTotal[p.id_emp] = p.id_emp
          }
          if (!totalTabla.sucTotal[p.id_suc]) {
            totalTabla.sucTotal[p.id_suc] = p.id_suc
          }
        }
        if (zonas[p.id_zon_suc] && !totalesZon[zonas[p.id_zon_suc]?.est_zon] && this.notasPorPedido[p.id_ped]) {
          totalesZon[zonas[p.id_zon_suc].est_zon] = zonas[p.id_zon_suc]
        }
        if (totalesZon[zonas[p.id_zon_suc]?.est_zon]) {
          for (const idPre in this.notasPorPedido[p.id_ped]) {
            if (this.presentacionesTodas[idPre]) {
              const cantidad = Number(this.notasPorPedido[p.id_ped][idPre].cantidad)
              const peso = Number((Number(this.presentacionesTodas[idPre].pesm_pre) * cantidad).toFixed(2))

              totalTabla.kiPedidos += peso
              totalTabla.canPedida += cantidad
            }
          }
        }
      })
      return totalTabla
    },
    totalesPorZonas () {
      if (!this.data || !this.data.nop) return {}
      const totalesZon = {}
      const zonas = []
      const cli = {}
      if (!this.data || !this.data.zon) return {}
      this.data.zon.forEach((z) => {
        if (z.eli_zon === '1') {
          zonas[z.id_zon] = z
        }
      })
      let listZon = 1
      this.data.zonaPed.forEach((zp) => {
        if (!cli[zp.est_zon]) {
          this.sucursalesZonas[zp.est_zon] = 0
          cli[zp.est_zon] = {}
        }
        if (zonas[zp.id_zon] && !totalesZon[zp.est_zon]) {
          totalesZon[zp.est_zon] = zonas[zp.id_zon]
          totalesZon[zp.est_zon].list = listZon
          totalesZon[zp.est_zon].peso = 0
          totalesZon[zp.est_zon].clientes = {}
          totalesZon[zp.est_zon].cantidad = 0
          totalesZon[zp.est_zon].existenciaPeso = 0
          totalesZon[zp.est_zon].notas = {}
          totalesZon[zp.est_zon].despachable = true
          listZon++
        }
      })
      this.data.ped.forEach((p) => {
        if (this.notasPorPedido[p.id_ped]) {
          if (!cli[zonas[p.id_zon_suc].est_zon][p.id_emp]) {
            cli[zonas[p.id_zon_suc].est_zon][p.id_emp] = {
              nom_emp: p.nom_emp,
              suc: {}
            }
          }
          if (!cli[zonas[p.id_zon_suc].est_zon][p.id_emp].suc[p.id_suc]) {
            this.sucursalesZonas[zonas[p.id_zon_suc].est_zon] = this.sucursalesZonas[zonas[p.id_zon_suc].est_zon] + 1
            cli[zonas[p.id_zon_suc].est_zon][p.id_emp].suc[p.id_suc] = {
              nom_suc: p.nom_suc,
              cp: '',
              tpre: '',
              ped: p.id_ped,
              descuento: p.dsc_ped
            }
            this.tipPrecio.forEach((tp) => {
              if (tp.id_tpre.includes(p.id_tpre_ped)) {
                cli[zonas[p.id_zon_suc].est_zon][p.id_emp].suc[p.id_suc].tpre = tp.nom_tpre
              }
            })
            this.conPago.forEach((cp) => {
              if (cp.id_cp.includes(p.id_cp_ped)) {
                cli[zonas[p.id_zon_suc].est_zon][p.id_emp].suc[p.id_suc].cp = cp.nom_cp
              }
            })
          }
        }
        if (totalesZon[zonas[p.id_zon_suc]?.est_zon]) {
          for (const idPre in this.notasPorPedido[p.id_ped]) {
            if (this.presentacionesTodas[idPre]) {
              if (totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre]) {
                totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].cantidad += Number(this.notasPorPedido[p.id_ped][idPre].cantidad)
              } else {
                totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre] = {
                  saldo: 0,
                  saldo_peso: 0
                }
                totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].cantidad = Number(this.notasPorPedido[p.id_ped][idPre].cantidad)

                for (const cat in this.categorias) {
                  if (this.categorias[cat].productos[this.presentacionesTodas[idPre].id_pro_pre]) {
                    totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].producto = this.categorias[cat].productos[this.presentacionesTodas[idPre].id_pro_pre].nom_pro
                    totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].categoria = this.categorias[cat].nom_cat
                  }
                }
              }
              const cantidad = Number(this.notasPorPedido[p.id_ped][idPre].cantidad)
              const peso = Number((Number(this.presentacionesTodas[idPre].pesm_pre) * cantidad).toFixed(2))
              const existenciaCan = Number(this.presentacionesTodas[idPre].can_pre)
              const existenciaPe = Number((Number(this.presentacionesTodas[idPre].pesm_pre) * existenciaCan).toFixed(2))
              if (peso > existenciaPe) {
                totalesZon[zonas[p.id_zon_suc].est_zon].despachable = false
              }
              if (totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].peso) {
                totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].peso += peso
              } else {
                totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].peso = peso
              }
              totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].existenciaCantidad = existenciaCan
              totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].existenciaPeso = existenciaPe
              totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].saldo = Number(totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].existenciaCantidad - totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].cantidad)
              totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].saldo_peso = Number(totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].existenciaPeso - totalesZon[zonas[p.id_zon_suc].est_zon].notas[idPre].peso)

              totalesZon[zonas[p.id_zon_suc].est_zon].peso += peso
              totalesZon[zonas[p.id_zon_suc].est_zon].cantidad += cantidad
              totalesZon[zonas[p.id_zon_suc].est_zon].existenciaPeso += existenciaPe
              totalesZon[zonas[p.id_zon_suc].est_zon].clientes = cli[zonas[p.id_zon_suc].est_zon]
            }
          }
        }
      })
      return Object.values(totalesZon).sort((a, b) => a.list - b.list)
    },
    totalesPorProducto () {
      if (!this.data || !this.data.nop) return {}
      const totalesPro = {}
      const ven = []
      const zonas = []
      const cli = {}
      if (!this.data || !this.data.ven) return {}
      this.data.ven.forEach((v) => {
        ven[v.id_per] = v
      })
      this.data.zon.forEach((z) => {
        if (z.eli_zon === '1') {
          zonas[z.id_zon] = z
        }
      })
      this.data.ped.forEach((p) => {
        if (this.notasPorPedido[p.id_ped]) {
          if (!cli[zonas[p.id_zon_suc].est_zon]) {
            cli[zonas[p.id_zon_suc].est_zon] = {}
          }
          if (!cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc]) {
            cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc] = {}
          }
          if (!cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc][p.id_emp]) {
            cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc][p.id_emp] = {
              nom_emp: p.nom_emp,
              zon: p.id_zon_suc,
              ven: p.id_per_v_suc,
              suc: {}
            }
          }
          if (!cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc][p.id_emp].suc[p.id_suc]) {
            cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc][p.id_emp].suc[p.id_suc] = {
              nom_suc: p.nom_suc,
              cp: '',
              tpre: '',
              ped: p.id_ped,
              descuento: p.dsc_ped,
              obs: p.obs_ped
            }
            this.tipPrecio.forEach((tp) => {
              if (tp.id_tpre.includes(p.id_tpre_ped)) {
                cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc][p.id_emp].suc[p.id_suc].tpre = tp.nom_tpre
              }
            })
            this.conPago.forEach((cp) => {
              if (cp.id_cp.includes(p.id_cp_ped)) {
                cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc][p.id_emp].suc[p.id_suc].cp = cp.nom_cp
              }
            })
          }
        }
        for (const idPre in this.notasPorPedido[p.id_ped]) {
          if (this.presentacionesTodas[idPre]) {
            if (!totalesPro[this.presentacionesTodas[idPre].id_cat_pro]) {
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro] = this.categoriasTodas[this.presentacionesTodas[idPre].id_cat_pro]
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].peso = 0
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].clientes = {}
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].cantidad = 0
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].existenciaPeso = 0
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas = {}
            }
            if (totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre]) {
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].cantidad += Number(this.notasPorPedido[p.id_ped][idPre].cantidad)
              if (!totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].estados[p.id_zon_suc]) totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].estados[p.id_zon_suc] = zonas[p.id_zon_suc]
              if (!totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].vendedores[p.id_per_v_suc]) totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].vendedores[p.id_per_v_suc] = ven[p.id_per_v_suc]
              if (cli[zonas[p.id_zon_suc].est_zon] && cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc]) {
                if (!totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].clientes[p.id_zon_suc]) {
                  totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].clientes[zonas[p.id_zon_suc].est_zon] = {}
                  totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].clientes[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc] = cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc]
                } else {
                  totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].clientes[zonas[p.id_zon_suc].est_zon].assign(totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].clientes[zonas[p.id_zon_suc].est_zon]
                    , cli[zonas[p.id_zon_suc].est_zon])
                }
              }
            } else {
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre] = {
                saldo: 0,
                saldo_peso: 0,
                estados: {},
                vendedores: {},
                clientes: {},
                despachable: true
              }
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].cantidad = Number(this.notasPorPedido[p.id_ped][idPre].cantidad)
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].estados[p.id_zon_suc] = zonas[p.id_zon_suc]
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].vendedores[p.id_per_v_suc] = ven[p.id_per_v_suc]
              if (cli[zonas[p.id_zon_suc].est_zon] && cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc]) {
                totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].clientes[zonas[p.id_zon_suc].est_zon] = {}
                totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].clientes[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc] = cli[zonas[p.id_zon_suc].est_zon][p.id_per_v_suc]
              }

              for (const cat in this.categoriasTodas) {
                if (this.categoriasTodas[cat].productos[this.presentacionesTodas[idPre].id_pro_pre]) {
                  totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].producto = this.categoriasTodas[cat].productos[this.presentacionesTodas[idPre].id_pro_pre].nom_pro
                  totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].presentacion = this.presentacionesTodas[idPre].des_pre
                  totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].categoria = this.categoriasTodas[cat].nom_cat
                }
              }
            }
            const cantidad = Number(this.notasPorPedido[p.id_ped][idPre].cantidad)
            const peso = Number((Number(this.presentacionesTodas[idPre].pesm_pre) * cantidad).toFixed(2))
            const existenciaCan = Number(this.presentacionesTodas[idPre].can_pre)
            const existenciaPe = Number((Number(this.presentacionesTodas[idPre].pesm_pre) * existenciaCan).toFixed(2))
            if (peso > existenciaPe) {
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].despachable = false
            }
            if (totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].peso) {
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].peso += peso
            } else {
              totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].peso = peso
            }
            totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].existenciaCantidad = existenciaCan
            totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].existenciaPeso = existenciaPe
            totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].saldo = Number(totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].existenciaCantidad - totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].cantidad)
            totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].saldo_peso = Number(totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].existenciaPeso - totalesPro[this.presentacionesTodas[idPre].id_cat_pro].notas[idPre].peso)

            totalesPro[this.presentacionesTodas[idPre].id_cat_pro].peso += peso
            totalesPro[this.presentacionesTodas[idPre].id_cat_pro].cantidad += cantidad
            totalesPro[this.presentacionesTodas[idPre].id_cat_pro].existenciaPeso += existenciaPe
          }
        }
      })
      return totalesPro
    },
    totalesPorVendedor () {
      if (!this.data || !this.data.nop) return {}
      const totalesVen = {}
      const ven = []
      const cli = {}
      if (!this.data || !this.data.ven) return {}
      this.data.ven.forEach((v) => {
        ven[v.id_per] = v
      })
      let listVen = 1
      this.data.venPed.forEach((vp) => {
        if (!cli[vp.id_per_v_suc]) {
          this.sucursalesVendedor[vp.id_per_v_suc] = 0
          cli[vp.id_per_v_suc] = {}
        }
        if (ven[vp.id_per_v_suc] && !totalesVen[vp.id_per_v_suc]) {
          totalesVen[vp.id_per_v_suc] = ven[vp.id_per_v_suc]
          totalesVen[vp.id_per_v_suc].list = listVen
          totalesVen[vp.id_per_v_suc].peso = 0
          totalesVen[vp.id_per_v_suc].clientes = {}
          totalesVen[vp.id_per_v_suc].cantidad = 0
          totalesVen[vp.id_per_v_suc].existenciaPeso = 0
          totalesVen[vp.id_per_v_suc].notas = {}
          totalesVen[vp.id_per_v_suc].despachable = true
          listVen++
        }
      })
      this.data.ped.forEach((p) => {
        if (this.notasPorPedido[p.id_ped]) {
          if (!cli[p.id_per_v_suc][p.id_emp]) {
            cli[p.id_per_v_suc][p.id_emp] = {
              nom_emp: p.nom_emp,
              suc: {}
            }
          }
          if (!cli[p.id_per_v_suc][p.id_emp].suc[p.id_suc]) {
            this.sucursalesVendedor[p.id_per_v_suc] = this.sucursalesVendedor[p.id_per_v_suc] + 1
            cli[p.id_per_v_suc][p.id_emp].suc[p.id_suc] = {
              nom_suc: p.nom_suc,
              cp: '',
              tpre: '',
              ped: p.id_ped,
              descuento: p.dsc_ped
            }
            this.tipPrecio.forEach((tp) => {
              if (tp.id_tpre.includes(p.id_tpre_ped)) {
                cli[p.id_per_v_suc][p.id_emp].suc[p.id_suc].tpre = tp.nom_tpre
              }
            })
            this.conPago.forEach((cp) => {
              if (cp.id_cp.includes(p.id_cp_ped)) {
                cli[p.id_per_v_suc][p.id_emp].suc[p.id_suc].cp = cp.nom_cp
              }
            })
          }
        }
        if (totalesVen[p.id_per_v_suc]) {
          for (const idPre in this.notasPorPedido[p.id_ped]) {
            if (this.presentacionesTodas[idPre]) {
              if (totalesVen[p.id_per_v_suc].notas[idPre]) {
                totalesVen[p.id_per_v_suc].notas[idPre].cantidad += Number(this.notasPorPedido[p.id_ped][idPre].cantidad)
              } else {
                totalesVen[p.id_per_v_suc].notas[idPre] = {
                  saldo: 0,
                  saldo_peso: 0
                }
                totalesVen[p.id_per_v_suc].notas[idPre].cantidad = Number(this.notasPorPedido[p.id_ped][idPre].cantidad)

                for (const cat in this.categorias) {
                  if (this.categorias[cat].productos[this.presentacionesTodas[idPre].id_pro_pre]) {
                    totalesVen[p.id_per_v_suc].notas[idPre].producto = this.categorias[cat].productos[this.presentacionesTodas[idPre].id_pro_pre].nom_pro
                    totalesVen[p.id_per_v_suc].notas[idPre].categoria = this.categorias[cat].nom_cat
                  }
                }
              }
              const cantidad = Number(this.notasPorPedido[p.id_ped][idPre].cantidad)
              const peso = Number((Number(this.presentacionesTodas[idPre].pesm_pre) * cantidad).toFixed(2))
              const existenciaCan = Number(this.presentacionesTodas[idPre].can_pre)
              const existenciaPe = Number((Number(this.presentacionesTodas[idPre].pesm_pre) * existenciaCan).toFixed(2))
              if (peso > existenciaPe) {
                totalesVen[p.id_per_v_suc].despachable = false
              }
              if (totalesVen[p.id_per_v_suc].notas[idPre].peso) {
                totalesVen[p.id_per_v_suc].notas[idPre].peso += peso
              } else {
                totalesVen[p.id_per_v_suc].notas[idPre].peso = peso
              }
              totalesVen[p.id_per_v_suc].notas[idPre].existenciaCantidad = existenciaCan
              totalesVen[p.id_per_v_suc].notas[idPre].existenciaPeso = existenciaPe
              totalesVen[p.id_per_v_suc].notas[idPre].saldo = Number(totalesVen[p.id_per_v_suc].notas[idPre].existenciaCantidad - totalesVen[p.id_per_v_suc].notas[idPre].cantidad)
              totalesVen[p.id_per_v_suc].notas[idPre].saldo_peso = Number(totalesVen[p.id_per_v_suc].notas[idPre].existenciaPeso - totalesVen[p.id_per_v_suc].notas[idPre].peso)

              totalesVen[p.id_per_v_suc].peso += peso
              totalesVen[p.id_per_v_suc].cantidad += cantidad
              totalesVen[p.id_per_v_suc].existenciaPeso += existenciaPe
              totalesVen[p.id_per_v_suc].clientes = cli[p.id_per_v_suc]
            }
          }
        }
      })
      return Object.values(totalesVen).sort((a, b) => a.list - b.list)
    },
    cargaPedido () {
      if (!this.zonasPed) return {}
      const cargaPedido = {}
      for (const z in this.zonasPed) {
        for (const ped in this.zonasPed[z].pedidos) {
          for (const pre in this.zonasPed[z].pedidos[ped].notas) {
            if (!cargaPedido[z]) {
              cargaPedido[z] = {}
            }
            if (!cargaPedido[z][ped]) {
              cargaPedido[z][ped] = {}
            }
            if (!cargaPedido[z][ped][pre]) {
              cargaPedido[z][ped][pre] = ''
            }
          }
        }
      }
      return cargaPedido
    },
    totalPesoPedido () {
      let total = 0
      for (const indiceDetalle in this.estadoDetalles?.notas) {
        total += Number(this.estadoDetalles?.notas[indiceDetalle]?.peso)
      }
      return total
    },
    nuevos () {
      const nuevos = {
        porPed: {},
        porSuc: {}
      }
      if (!this.data || !this.data.nuevos) return nuevos
      this.data.nuevos.forEach((n) => {
        nuevos.porPed[n.id_ped] = true
        nuevos.porSuc[n.id_suc_ped] = true
      })
      return nuevos
    },
    moroso () {
      const nuevos = {
        porSuc: {}
      }
      if (!this.data || !this.data.promAtraso) return nuevos
      this.data.promAtraso.forEach((n) => {
        nuevos.porSuc[n.id_suc_fac] = n
      })
      return nuevos
    }
  },
  watch: {
    zonasPed: function () {
      if (Object.keys(this.zonasPed).length) {
        this.$emit('directo', false)
      } else {
        this.$emit('directo', true)
      }
    },
    botonCargar: function () {
      if (this.botonCargar) {
        this.cargarAlCamion()
        this.$emit('boton-cargado', false)
      }
    }
  },
  methods: {
    notasPedidoModal (suc) {
      this.notasPedido.suc = suc
      this.notasPedido.notas = {}
      this.data.nop.forEach((np) => {
        if (np.id_ped_np === suc.ped) {
          if (this.presentacionesTodas[np.id_pre_np]) {
            this.notasPedido.notas[np.id_pre_np] = {
              nombre: this.categoriasTodas[this.presentacionesTodas[np.id_pre_np].id_cat_pro].productos[this.presentacionesTodas[np.id_pre_np].id_pro_pre].nom_pro,
              categoria: this.categoriasTodas[this.presentacionesTodas[np.id_pre_np].id_cat_pro].nom_cat,
              descripcion: this.presentacionesTodas[np.id_pre_np].des_pre,
              empaque: this.presentacionesTodas[np.id_pre_np].tip_pre,
              codigo: this.presentacionesTodas[np.id_pre_np].cod_pre,
              cantidad: np.can_ped_np,
              peso: Number((np.can_ped_np * Number(this.presentacionesTodas[np.id_pre_np].pesm_pre)).toFixed(2)),
              unidad: this.presentacionesTodas[np.id_pre_np].uni_paq_pre,
              despachado: np.can_np,
              costo: this.presentacionesTodas[np.id_pre_np].pvmp_pre,
              iva: this.presentacionesTodas[np.id_pre_np].iva_pre
            }
          }
        }
      })
      this.mostrarNotasPedido = true
    },
    calcularSubTotal () {
      let subtotal = 0
      for (const nota in this.notasPedido.notas) {
        subtotal += this.notasPedido.notas[nota].costo * this.notasPedido.notas[nota].cantidad
      }
      return subtotal
    },
    calcularDescuento () {
      if (!this.notasPedido.suc) return 0
      return ((parseFloat(this.notasPedido.suc.descuento) / 100) * this.calcularSubTotal()).toFixed(2)
    },
    calcularTotalIva () {
      let iva = 0
      for (const nota in this.notasPedido.notas) {
        iva += (parseFloat(this.notasPedido.notas[nota].costo || 0) *
          parseFloat(this.notasPedido.notas[nota].cantidad) *
          parseFloat(this.notasPedido.notas[nota].iva)) /
        100
      }
      return Number((iva).toFixed(2))
    },
    calcularMontoTotal () {
      return (parseFloat(this.calcularSubTotal()) - parseFloat(this.calcularDescuento()) + parseFloat(this.calcularTotalIva())).toFixed(2) || 0
    },
    cantidadDes (cantidad, despachado) {
      return Number(cantidad) === Number(despachado) ? 0 : Number(cantidad) - Number(despachado)
    },
    calcularTotalPeso () {
      let peso = 0
      for (const nota in this.notasPedido.notas) {
        peso += this.notasPedido.notas[nota].peso
      }
      return Number((peso).toFixed(2))
    },
    calculaTotal (cantidad, precio) {
      return cantidad * precio
    },
    calcularIva (cantidad, precio = 0, iva = 0) {
      return (parseFloat(precio || 0) *
          parseFloat(cantidad) *
          parseFloat(iva)) /
        100
    },
    volverAlResumen () {
      this.eliminarFiltrosSeleccionados()
      this.directo = true
      this.restablecerFiltrado = true
    },
    mostrarAlerta (mensaje) {
      this.alertaMensaje = {
        contenido: mensaje.contenido,
        tipo: mensaje.tipo
      }
    },
    despacharResumen (id) {
      if (this.resumenMode === 'zonas') {
        this.estadosAFiltrar = []
        this.estadosAFiltrar.push(id)
        this.municipioAFiltrar = []
        this.parroquiaAFiltrar = []
      } else if (this.resumenMode === 'vendedor') {
        this.vendedorSeleccionado = []
        this.vendedoresAFiltrar = []
        this.vendedoresAFiltrar.push(id)
      } else {
        this.estadosAFiltrar = []
        this.municipioAFiltrar = []
        this.parroquiaAFiltrar = []
        this.vendedorSeleccionado = []
        this.vendedoresAFiltrar = []
        for (const i in id.estados) {
          this.estadosAFiltrar.push(id.estados[i].est_zon)
        }
        for (const i in id.vendedores) {
          this.vendedoresAFiltrar.push(id.vendedores[i].id_per)
        }
      }

      this.aplicarFiltros()
      this.seleccionarTodosP = true
      this.categoriasAMostrar = ['1', '2', '3']
      this.seleccionarTodos(this.seleccionarTodosP)
    },
    seleccionarTodos (checked, idPe, z) {
      this.categoriasAMostrar = ['1', '2', '3']
      if (idPe) {
        for (const p in this.cargaPedido[z][idPe]) {
          if (this.presentaciones[p] && this.categoriasTodas[this.presentaciones[p].id_cat_pro]) {
            this.cargaPedido[z][idPe][p] = checked ? this.cargaPedido[z][idPe][p] = this.notasPorPedido[idPe][p]?.cantidad : this.cargaPedido[z][idPe][p] = ''
          }
        }
      } else {
        for (const z in this.zonasPed) {
          for (const idPe in this.zonasPed[z].pedidos) {
            for (const p in this.cargaPedido[z][idPe]) {
              if (this.presentaciones[p] && this.categoriasTodas[this.presentaciones[p].id_cat_pro]) {
                this.cargaPedido[z][idPe][p] = checked ? this.cargaPedido[z][idPe][p] = this.notasPorPedido[idPe][p]?.cantidad : this.cargaPedido[z][idPe][p] = ''
              }
            }
          }
        }
      }
      this.calcularTotales()
    },
    cargarAlCamion () {
      const camion = {}
      const camionTotal = {
        cantidad: 0,
        peso: 0,
        volumen: 0
      }
      for (const zona in this.cargaPedido) {
        for (const pedido in this.cargaPedido[zona]) {
          for (const presentacion in this.cargaPedido[zona][pedido]) {
            if (Object.keys(this.cargaPedido[zona][pedido][presentacion]).length) {
              if (this.presentaciones[presentacion]) {
                if (!camion[zona]) {
                  camion[zona] = {}
                  camionTotal[zona] = {}
                }
                if (!camion[zona][pedido]) {
                  camion[zona][pedido] = {}
                }
                if (!camionTotal[zona][presentacion]) {
                  camionTotal[zona][presentacion] = {}
                  camionTotal[zona][presentacion].cantidad = {}
                  camionTotal[zona][presentacion].peso = {}
                  camionTotal[zona][presentacion].volumen = {}
                }
                camion[zona][pedido][presentacion] = this.cargaPedido[zona][pedido][presentacion]
                camionTotal[zona][presentacion].cantidad = this.totalesPorPresentacion[zona][presentacion].cantidad
                camionTotal[zona][presentacion].peso = this.totalesPorPresentacion[zona][presentacion].peso
                camionTotal[zona][presentacion].volumen = this.totalesPorPresentacion[zona][presentacion].volumen
                camionTotal.cantidad = this.totalesPorPresentacion.cantidad
                camionTotal.peso = this.totalesPorPresentacion.peso
                camionTotal.volumen = this.totalesPorPresentacion.volumen
              }
            }
          }
        }
      }
      this.camion = camion
      this.camionTotal = camionTotal
      this.listaTransporte = {
        transportistas: this.data.cho,
        vehiculos: this.data.veh
      }
      this.modalCargarCamion = true
    },
    completoZona () {
      apiPost({ s: 'pedZonaComplMv' }, 'est=' + this.pedidosCom)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: res.data.e,
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.pedidosCom = ''
            this.$emit('actualizar')
            this.volverAlResumen()
          } else {
            this.alertaMensaje = {
              contenido: res.data.e,
              tipo: 'error'
            }
            this.pedidosCom = ''
          }
        })
    },
    completoVendedor () {
      apiPost({ s: 'pedVenComplMv' }, 'ven=' + this.pedidosCom)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: res.data.e,
              tipo: 'correcto'
            }
            this.modalConfirmacionEliminar = false
            this.pedidosCom = ''
            this.$emit('actualizar')
            this.volverAlResumen()
          } else {
            this.alertaMensaje = {
              contenido: res.data.e,
              tipo: 'error'
            }
            this.pedidosCom = ''
          }
        })
    },
    calcularTotales () {
      const totales = {
        cantidad: 0,
        peso: 0,
        volumen: 0
      }
      const totalesPed = {}
      for (const zona in this.cargaPedido) {
        totales[zona] = {
          cantidad: 0,
          peso: 0,
          volumen: 0
        }
        totalesPed[zona] = {}
        for (const pedido in this.cargaPedido[zona]) {
          for (const presentacion in this.cargaPedido[zona][pedido]) {
            if (this.presentaciones[presentacion]) {
              if (!totales[zona][presentacion]) {
                totales[zona][presentacion] = {
                  cantidad: 0,
                  peso: 0,
                  volumen: 0
                }
              }
              if (!totalesPed[zona][pedido]) {
                totalesPed[zona][pedido] = {
                  cantidad: 0,
                  peso: 0,
                  volumen: 0
                }
              }
              if (Number(this.cargaPedido[zona][pedido][presentacion]) > this.presentaciones[presentacion].can_pre || totales[zona][presentacion].cantidad > Number(this.presentaciones[presentacion].can_pre)) {
                this.alertaMensaje = {
                  contenido: `La cantidad ingresada es mayor a la cantidad disponible de la presentación ${this.presentaciones[presentacion].des_pre}`,
                  tipo: 'advertencia'
                }
                this.cargaPedido[zona][pedido][presentacion] = 0
              }
              const cantidad = Number(this.cargaPedido[zona][pedido][presentacion])
              const volumen = Number((cantidad * Number(this.presentaciones[presentacion].volm_pre)).toFixed(2))
              const peso = Number((cantidad * Number(this.presentaciones[presentacion].pesm_pre)).toFixed(2))
              totales[zona][presentacion].cantidad += cantidad
              totales[zona][presentacion].peso += peso
              totales[zona][presentacion].volumen += Number((cantidad * Number(this.presentaciones[presentacion].vol_pre)).toFixed(2))
              totalesPed[zona][pedido].cantidad += cantidad
              totalesPed[zona][pedido].peso += peso
              totalesPed[zona][pedido].volumen += Number((cantidad * Number(this.presentaciones[presentacion].vol_pre)).toFixed(2))
              totales[zona].peso += peso
              totales[zona].volumen += volumen
              totales[zona].cantidad += cantidad
              totales.cantidad += cantidad
              totales.volumen += volumen
              totales.peso += peso
            }
          }
        }
      }
      this.totalesPorPresentacion = totales
      this.totalesPorPedido = totalesPed
      this.cargando = false
    },
    aplicarFiltros () {
      this.restablecerFiltrado = false
      this.directo = false
      this.$emit('directo', false)
      this.filtrosUsados = 0
      this.zonasPed = {}
      this.vendedorSeleccionado = []

      if (this.parroquiasAFiltrar.length) {
        this.data.ped.forEach((p) => {
          if (this.estadosAFiltrar.includes(this.zonas[p.id_zon_suc].est_zon) && this.municipiosAFiltrar.includes(this.zonas[p.id_zon_suc].ciu_zon) && this.parroquiasAFiltrar.includes(this.zonas[p.id_zon_suc].sec_zon)) {
            if (this.notasPorPedido[p.id_ped]) {
              if (!this.zonasPed[p.id_zon_suc]) {
                this.zonasPed[p.id_zon_suc] = JSON.parse(JSON.stringify(this.zonas[p.id_zon_suc]))
              }
              this.zonasPed[p.id_zon_suc].pedidos[p.id_ped] = p
              this.zonasPed[p.id_zon_suc].pedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            }
          }
        })
      } else if (this.municipiosAFiltrar.length) {
        this.data.ped.forEach((p) => {
          if (this.estadosAFiltrar.includes(this.zonas[p.id_zon_suc].est_zon) && this.municipiosAFiltrar.includes(this.zonas[p.id_zon_suc].ciu_zon)) {
            if (this.notasPorPedido[p.id_ped]) {
              if (!this.zonasPed[p.id_zon_suc]) {
                this.zonasPed[p.id_zon_suc] = JSON.parse(JSON.stringify(this.zonas[p.id_zon_suc]))
              }
              this.zonasPed[p.id_zon_suc].pedidos[p.id_ped] = p
              this.zonasPed[p.id_zon_suc].pedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            }
          }
        })
      } else if (this.estadosAFiltrar.length) {
        this.data.ped.forEach((p) => {
          if (this.estadosAFiltrar.includes(this.zonas[p.id_zon_suc].est_zon)) {
            if (this.notasPorPedido[p.id_ped]) {
              if (!this.zonasPed[p.id_zon_suc]) {
                this.zonasPed[p.id_zon_suc] = JSON.parse(JSON.stringify(this.zonas[p.id_zon_suc]))
              }
              this.zonasPed[p.id_zon_suc].pedidos[p.id_ped] = p
              this.zonasPed[p.id_zon_suc].pedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            }
          }
        })
      }

      if (this.estadosAFiltrar.length) {
        this.filtrosUsados++
      }
      if (this.vendedoresAFiltrar.length) {
        this.aplicarFiltrosVen()
        this.filtrosUsados++
      }
      if (this.tiposClienteAFiltrar.length) {
        this.aplicarFiltrosTipoCliente()
        this.filtrosUsados++
      }
      if (this.condiPagoAFiltrar.length) {
        this.aplicarFiltrosCondiPago()
        this.filtrosUsados++
      }
      if (this.tipoPrecioAFiltrar.length) {
        this.aplicarFiltrosTiPre()
      }
      this.cargando = false
    },
    aplicarFiltrosZonas (estadosFil, municipiosFil, parroquiasFil) {
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []

      estadosFil.forEach(e => {
        this.estadosAFiltrar.push(e.est_zon)
      })
      municipiosFil.forEach(m => {
        this.municipiosAFiltrar.push(m.ciu_zon)
      })
      parroquiasFil.forEach(p => {
        this.parroquiasAFiltrar.push(p.sec_zon)
      })

      this.aplicarFiltros()
    },
    aplicarFiltrosVen () {
      const zonasFiltradas = {}

      if (Object.keys(this.zonasPed).length || this.filtrosUsados) {
        for (const z in this.zonasPed) {
          for (const p in this.zonasPed[z].pedidos) {
            if (this.vendedoresAFiltrar.includes(this.zonasPed[z].pedidos[p].id_per_v_suc)) {
              if (!zonasFiltradas[z]) zonasFiltradas[z] = JSON.parse(JSON.stringify(this.zonas[z]))
              zonasFiltradas[z].pedidos[p] = this.zonasPed[z].pedidos[p]
            }
          }
        }
      } else {
        this.data.ped.forEach((p) => {
          if (this.vendedoresAFiltrar.includes(p.id_per_v_suc)) {
            if (this.notasPorPedido[p.id_ped]) {
              if (!zonasFiltradas[p.id_zon_suc]) {
                zonasFiltradas[p.id_zon_suc] = JSON.parse(JSON.stringify(this.zonas[p.id_zon_suc]))
              }
              zonasFiltradas[p.id_zon_suc].pedidos[p.id_ped] = p
              zonasFiltradas[p.id_zon_suc].pedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            }
          }
        })
      }
      this.zonasPed = zonasFiltradas
      this.vendedores.forEach(v => {
        for (const ven in this.vendedoresAFiltrar) {
          if (v.id_per === this.vendedoresAFiltrar[ven]) {
            this.vendedorSeleccionado.push(v)
          }
        }
      })
    },
    aplicarFiltrosTipoCliente () {
      const zonasFiltradas = {}

      if (Object.keys(this.zonasPed).length || this.filtrosUsados) {
        for (const z in this.zonasPed) {
          for (const p in this.zonasPed[z].pedidos) {
            if (this.tiposClienteAFiltrar.includes(this.zonasPed[z].pedidos[p].id_tcl_suc)) {
              if (!zonasFiltradas[z]) zonasFiltradas[z] = JSON.parse(JSON.stringify(this.zonas[z]))
              zonasFiltradas[z].pedidos[p] = this.zonasPed[z].pedidos[p]
            }
          }
        }
      } else {
        this.data.ped.forEach((p) => {
          if (this.tiposClienteAFiltrar.includes(p.id_tcl_suc)) {
            if (this.notasPorPedido[p.id_ped]) {
              if (!zonasFiltradas[p.id_zon_suc]) {
                zonasFiltradas[p.id_zon_suc] = JSON.parse(JSON.stringify(this.zonas[p.id_zon_suc]))
              }
              zonasFiltradas[p.id_zon_suc].pedidos[p.id_ped] = p
              zonasFiltradas[p.id_zon_suc].pedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            }
          }
        })
      }
      this.zonasPed = zonasFiltradas
    },
    aplicarFiltrosCondiPago () {
      const zonasFiltradas = {}

      if (Object.keys(this.zonasPed).length || this.filtrosUsados) {
        for (const z in this.zonasPed) {
          for (const p in this.zonasPed[z].pedidos) {
            if (this.condiPagoAFiltrar.includes(this.zonasPed[z].pedidos[p].id_cp_ped)) {
              if (!zonasFiltradas[z]) zonasFiltradas[z] = JSON.parse(JSON.stringify(this.zonas[z]))
              zonasFiltradas[z].pedidos[p] = this.zonasPed[z].pedidos[p]
            }
          }
        }
      } else {
        this.data.ped.forEach((p) => {
          if (this.condiPagoAFiltrar.includes(p.id_cp_ped)) {
            if (this.notasPorPedido[p.id_ped]) {
              if (!zonasFiltradas[p.id_zon_suc]) {
                zonasFiltradas[p.id_zon_suc] = JSON.parse(JSON.stringify(this.zonas[p.id_zon_suc]))
              }
              zonasFiltradas[p.id_zon_suc].pedidos[p.id_ped] = p
              zonasFiltradas[p.id_zon_suc].pedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            }
          }
        })
      }
      this.zonasPed = zonasFiltradas
    },
    aplicarFiltrosTiPre () {
      const zonasFiltradas = {}

      if (Object.keys(this.zonasPed).length || this.filtrosUsados) {
        for (const z in this.zonasPed) {
          for (const p in this.zonasPed[z].pedidos) {
            if (this.tipoPrecioAFiltrar.includes(this.zonasPed[z].pedidos[p].id_tpre_ped)) {
              if (!zonasFiltradas[z]) zonasFiltradas[z] = JSON.parse(JSON.stringify(this.zonas[z]))
              zonasFiltradas[z].pedidos[p] = this.zonasPed[z].pedidos[p]
            }
          }
        }
      } else {
        this.data.ped.forEach((p) => {
          if (this.tipoPrecioAFiltrar.includes(p.id_tpre_ped)) {
            if (this.notasPorPedido[p.id_ped]) {
              if (!zonasFiltradas[p.id_zon_suc]) {
                zonasFiltradas[p.id_zon_suc] = JSON.parse(JSON.stringify(this.zonas[p.id_zon_suc]))
              }
              zonasFiltradas[p.id_zon_suc].pedidos[p.id_ped] = p
              zonasFiltradas[p.id_zon_suc].pedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            }
          }
        })
      }
      this.zonasPed = zonasFiltradas
    },
    eliminarFiltrosSeleccionados () {
      this.vendedoresAFiltrar = []
      this.vendedorSeleccionado = []
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.tiposClienteAFiltrar = []
      this.condiPagoAFiltrar = []
      this.tipoPrecioAFiltrar = []
      this.zonasPed = {}
      this.categoriasAMostrar = ['1']
      this.directo = true
      this.$emit('directo', true)
      this.seleccionarTodosP = false
      this.calcularTotales()
    }
  }
}
</script>

<style lang="scss" scoped>
// Alineación del texto a la derecha de los encabezados de la tabla
.table.resumen .encabezado .thead div:not(:first-child),
.table.detalles .encabezado .thead div:not(:first-child) {
  @media screen and (min-width: 576px) {
    text-align: right;
  }
}

// Tabla Resumen breakpoints
.table.resumen {
  margin: 0 auto 3rem;
  max-width: 768px;

  // Define la altura del sticky
  .encabezado.sticky {
    top: 122px;

    // Por cambio de height en menú secundario
    @media screen and (min-width: 397px) {top: 88px;}
    @media screen and (min-width: 768px) {top: 57px;}
  }

  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
  .contenido > [class*="col"] {
    padding: .2rem 1rem;
  }

  .columna-despacho {
    @media screen and (min-width:576px) {
      width: 150px;
    }
  }
}
.table.clientes,
.table.detalles {
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
.table.p-notas {
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("768px");

  li .thead > [class|=col] {
    @media screen and (max-width: 768px) {
      font-size: 0.57rem;
    }
  }
  li.contenido > [class|=col] {
    @media screen and (max-width: 768px) {
      font-size: 0.7rem;
    }
  }
}
.fila-error {
  background-color: lighten($secundario, 48%);

  .texto-error {
    font-weight: bold;
  }
}
.bold {
  font-weight: 500;
}
[class*="tooltip-"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 1.25rem !important;
  position: relative;
  &[class*="filter"] {
    &::after {
      @include agregar-icono('\f0b0', 900);
      right: 0;
      position: absolute;
    }
  }
  &[class*="modal"] {
    &::after {
      @include agregar-icono('\f2d2', 900);
      right: 0;
      position: absolute;
    }

    &[class*="custom"] {
      &::after {
        right: 1rem;
      }
    }
  }
}
// Filas flex row nowrap
.row-nowrap {
  display: flex;
  flex-flow: row nowrap;
}
// Rotar titulo
.contenido-categoria {
  display: flex;
}
.contenido-categoria:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.rotar-titulo {
  font-size: .66rem;
  padding: .2rem;
  transform: scale(-1, -1);
  writing-mode: vertical-rl;
}
</style>
