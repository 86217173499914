import { formatearFechaYHora, formatearMesAnho, capitalizarPalabras } from '@/funciones/funciones'
/**
 * Formatos de impresión.
 * Ordenados alfabéticamente.
 * @returns {Object} Nombre del documento y Formato | Vista de impresión
 */

/**
 * TABLA DESPACHO TRANSPORTISTA
 * Componente: VerificacionCliente.vue
 * @param {String} transportista Nombre y apellido del transportista
 * @param {String} zonas Lista de zonas, separadas por comas
 * @param {Array} clientes Array de clientes, en estructura del table body
 * @param {number} clientesVerificar Array de los clientes por verificar
 */
function VerificacionClienteTransportista (transportista, zonas, clientes, clientesVerificar) {
  const nombre = `Despacho transportista ${transportista} - Zonas ${zonas}`
  const formato = [
    {
      columns: [
        {
          width: '50%',
          stack: [
            { text: `Transportista: ${transportista}` },
            {
              text: `Zonas: ${zonas}`,
              color: 'gray'
            },
            {
              text: `Clientes por verificar: ${clientesVerificar}`,
              color: 'gray'
            }
          ]
        },
        {
          width: '*',
          text: 'Verificación clientes',
          style: 'header1',
          alignment: 'right',
          margin: [0, 0, 0, 15]
        }
      ]
    },
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: [12, '*', 'auto', 'auto', '*', 'auto'],
        body: clientes
      }
    }
  ]

  return { nombre, formato }
}
/**
 * REPORTE: TABLA PEDIDO VS DESPACHO
 * Componente: PedidovsDespacho.vue
 * @param {Object} vendedor Nombre, apellido y id del vendedor
 * @param {String} fecha Fecha seleccionada del pedido
 * @param {Array} pedidos Array de los pedidos, en estructura del table body
 */
function PedidosvsDespacho (vendedor, fecha, pedidos) {
  // Establece los datos de vendedor/es
  let datosVendedor = ''
  if (vendedor.length > 1) {
    datosVendedor = `- Vendedores ${vendedor.join(', ')}`
  } else if (vendedor.length === 1) {
    datosVendedor = `- Vendedor ${vendedor[0]}`
  }

  // Ajusta formato de fecha a mm-aaaa
  fecha = formatearMesAnho(fecha)

  const nombre = `Reporte Pedidos vs Despacho - Mes ${fecha} ${datosVendedor}`
  const formato = [
    {
      margin: [0, 0, 0, 10],
      columns: [
        [
          {
            text: `Mes ${fecha}`
          },
          datosVendedor === ''
            ? { text: 'Todos los vendedores' }
            : { text: `${datosVendedor.slice(2)}` }
        ],
        [
          {
            text: 'Reporte',
            style: 'header6',
            alignment: 'right'
          },
          {
            text: 'Pedidos vs Despacho',
            style: 'header2',
            alignment: 'right'
          }
        ]
      ]
    },
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['40%', '20%', '20%', '20%'],
        body: pedidos
      }
    }
  ]

  return { nombre, formato }
}
/**
 * REPORTE: TABLA PENDIENTE VS DESPACHADO
 * Componente: PendientevsDespachado.vue
 * @param {String} fecha Fecha seleccionada del pedido
 * @param {Array} pedidos Array de los pedidos, en estructura del table body
 */
function PendientevsDespachado (fecha, pedidos) {
  // Ajusta formato de fecha a mm-aaaa
  fecha = formatearMesAnho(fecha)

  const nombre = `Reporte Pendiente vs Despachado - Mes ${fecha}`
  const formato = [
    {
      margin: [0, 0, 0, 10],
      columns: [
        [
          {
            text: `Mes: ${fecha}`
          },
          {
            text: ''
          }
        ],
        [
          {
            text: 'Reporte',
            style: 'header6',
            alignment: 'right'
          },
          {
            text: 'Pendiente vs Despachado',
            style: 'header2',
            alignment: 'right'
          }
        ]
      ]
    },
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['40%', '*', '*'/* '20%' */],
        body: pedidos
      }
    }
  ]

  return { nombre, formato }
}
/**
 * REPORTE: TABLA PEDIDO ACUMULADO VS DESPACHADO
 * Componente: PedidovsDespachado.vue
 * @param {String} fecha Fecha seleccionada del pedido
 * @param {Array} pedidos Array de los pedidos, en estructura del table body
 */
function PedidovsDespachado (fecha, pedidos) {
  const nombre = `Reporte Pedido acumulado vs Despachado - Mes ${fecha}`
  const formato = [
    {
      margin: [0, 0, 0, 10],
      columns: [
        [
          {
            text: `Fecha consultada: ${formatearMesAnho(fecha.fechaResumen)}`
          },
          {
            text: `Fecha de impresión: ${formatearFechaYHora(fecha.fechaImpresion)}`
          }
        ],
        [
          {
            text: 'Reporte',
            style: 'header6',
            alignment: 'right'
          },
          {
            text: 'Pedido acumulado vs Despachado',
            style: 'header2',
            alignment: 'right'
          }
        ]
      ]
    },
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['25%', 'auto', 'auto', '*', '*', 'auto'],
        body: pedidos
      }
    }
  ]

  return { nombre, formato }
}
/**
 * TABLA DESPACHO TRANSPORTISTA
 * Componente: Despachos.vue
 * @param {Array} encabezado Datos para el encabezado del reporte
 * @param {Array} productos Productos del despacho
 */
function ResumenCantidadesDespacho (encabezado, productos) {
  const nombre = `Resumen de cantidades del despacho de vendedor/es ${encabezado.vendedores}`
  const fecha = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2) + '-' + ('0' + (new Date().getDate())).substr(-2) + ' ' + ('0' + (new Date().getHours())).substr(-2) + ':' + ('0' + (new Date().getMinutes())).substr(-2) + ':' + ('0' + (new Date().getSeconds())).substr(-2)
  const formato = [
    {
      columns: [
        {
          width: '*',
          stack: [
            { text: 'INDUSTRIAS KEL, C.A. J-09005049-3', fontSize: '15', bold: true },
            { text: 'AV 2 NRO 63, SECTOR CAMPO ALEGRE MUNICIPIO SAN RAFAEL DE CARVAJAL' }
          ]
        },
        {
          width: 'auto',
          text: `Fecha: ${formatearFechaYHora(fecha)}`
        }
      ]
    },
    {
      text: 'DESPACHOS DEL DÍA',
      alignment: 'center',
      style: 'header',
      fontSize: 14,
      bold: true,
      margin: [0, 15, 0, 0]
    },
    {
      columns: [
        {
          width: '*',
          stack: [
            { text: [{ text: 'Vehículos:', bold: true }, ` ${encabezado.vehiculos}`] },
            { text: [{ text: 'Conductores:', bold: true }, ` ${encabezado.transportistas}`] }
          ]
        },
        {
          width: '*',
          stack: [
            { text: [{ text: 'Vendedores:', bold: true }, ` ${capitalizarPalabras(encabezado.vendedores)}`] },
            { text: [{ text: 'Estados:', bold: true }, ` ${encabezado.estados}`] }
          ]
        }
      ],
      margin: [0, 2, 0, 8]
    },
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['auto', '20%', '*', '12%', '15%'],
        body: productos
      },
      margin: [0, 5, 0, 0]
    }
  ]

  return { nombre, formato }
}

export {
  VerificacionClienteTransportista,
  PedidosvsDespacho,
  PendientevsDespachado,
  PedidovsDespachado,
  ResumenCantidadesDespacho
}
