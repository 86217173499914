import { render, staticRenderFns } from "./FiltrosTipo1.vue?vue&type=template&id=2e2ccdd2&scoped=true&"
import script from "./FiltrosTipo1.vue?vue&type=script&lang=js&"
export * from "./FiltrosTipo1.vue?vue&type=script&lang=js&"
import style0 from "./FiltrosTipo1.vue?vue&type=style&index=0&id=2e2ccdd2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2ccdd2",
  null
  
)

export default component.exports