import { render, staticRenderFns } from "./Distribucion.vue?vue&type=template&id=2868337c&scoped=true&"
import script from "./Distribucion.vue?vue&type=script&lang=js&"
export * from "./Distribucion.vue?vue&type=script&lang=js&"
import style0 from "./Distribucion.vue?vue&type=style&index=0&id=2868337c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2868337c",
  null
  
)

export default component.exports